import React, { useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import { useNavigate, useNavigationType, useParams } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Container,
  Grid,
  InputLabel,
  Snackbar,
  SnackbarContent,
  TextField,
  Typography,
} from "@mui/material";
import Navigation from "./navigation";
import CustomerService from "../services/customer/customerService";
import CartService from "../services/customer/cartService";
import { useAuth } from "../authContext";
import WishlistService from "../services/customer/wishlistService";
import Footer from "./components/footer/footer";
import noImage from "../assets/no-image.png";
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ItemCarousel from "./components/item/carousel";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ShowMoreCard from "./components/item/showMoreCard";
import ItemCard from "./components/item/itemCard";
import { colorWhite, headerStyle, subHeaderStyle, textStyle, textStyleContrast, textSmallStyle, orangeHex } from "../helper/style";
import LiqueAccordion from "./components/liqueAccordion";

const ItemDetailPage2 = () => {
  const [ShowSuccess, setShowSuccess] = useState(false);
  const [MsgSuccess, setMsgSuccess] = useState("");
  const [showError, setShowError] = useState(false);
  const [msgError, setMsgError] = useState();
  const [errors, setErrors] = useState(null);
  const [isBookmarked, setBookmarked] = useState(false);
  const [similarProduct, setSimilarProduct] = useState([]);
  const [item, setItems] = useState(null);
  const [size, setSize] = useState("");
  const [variant, setVariant] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [postCode, setPostCode] = useState("");
  const [OpenWishlistSnack, setopenWishlistSnack] = useState(false);
  const [loadingWishlist, setLoadingWishlist] = useState(false);
  const [loadingCalculateFee, setLoadingCalculateFee] = useState(false);
  const [OpenSuccessSnack, setopenSuccessSnack] = useState(false);
  const [deliveryFee, setDeliveryFee] = useState(null);
  const [imageIndex, setImageIndex] = useState(0);
  const [loadingAddItem, setLoadingAddItem] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const navigationType = useNavigationType();
  const imageUrl = "https://api.liquestore.com/assets/items/"

  const { auth } = useAuth();

  const btnStyle = {
    borderRadius: "12px",
    width: "100%",
    backgroundColor: "white",
    color: "black",
    border: "1px solid white",
    padding: 5,
    textTransform: "unset"
  };

  const btnStyleOutlined = {
    borderRadius: "12px",
    width: "100%",
    backgroundColor: "transparent",
    border: "1px solid #505862",
    padding: 5,
    textTransform: "unset"
  };

  const qtyBtnStyle = {
    width: "30px",
    height: "30px",
    color: "black",
    background: "white",
    padding: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "15px",
    cursor: "pointer",
    border: "none",
    fontWeight: "bold",
    fontSize: "1em"
  }

  const actionWishlist = (
    <>
      <Button
        color="secondary"
        size="small"
        onClick={() => navigate("/customer/wishlist")}
      >
        Open Wishlist
      </Button>
    </>
  );

  const action = (
    <>
      <Button
        color="secondary"
        size="small"
        onClick={() => navigate("/customer/cart")}
      >
        Open Cart
      </Button>
    </>
  );

  const activateSuccess = () => {
    setShowSuccess(true);
    setTimeout(() => {
      setShowSuccess(false);
    }, 5000);
  };

  const activateError = () => {
    setShowError(true);
    setTimeout(() => {
      setShowError(false);
    }, 5000);
  };

  const handleChangeSize = (size) => {
    setSize(size);
    
    const variant = item?.variantModels.find(v => v.id === size);
    console.log(variant)
    setVariant(variant);

    if (quantity > variant.quantity) {
      setQuantity(variant.quantity)
    }
  };

  const handleBookmark = async () => {
    if (auth?.user) {
      setLoadingWishlist(true);
      if (isBookmarked) {
        await deleteWishlist();
      } else {
        await addWishlist();
      }
      setLoadingWishlist(false);
    } else {
      navigate("/login");
    }
  }

  const handleQuantityChange = (value) => {
    if (!value) {
      value = '1';
    }

    if (!isNaN(parseInt(value))) {
      if (parseInt(value) < 1) {
        value = '1';
      }
    }

    let number = value.replace(/\D/g,'');
    if (variant) {
      if (number > variant.quantity) {
        number = variant.quantity
      }
    }

    setQuantity(parseInt(number))
  }

  const handleAddQuantity = () => {
    let qty = quantity + 1;
    if (variant) {
      if (qty > variant.quantity) {
        qty = variant.quantity
      }
    }

    setQuantity(qty)
  }

  const handleMinusQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1)
    }
  }

  const validate = () => {
    let tempErrors = {};

    if (postCode.length !== 5) {
      tempErrors.postCode = "Kode pos harus berisi 5 digit";
    }

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const fetchItemDetail = async () => {
    try {
      const responseItem = await CustomerService.getItemDetail(id);
      setItems(responseItem.data);
      // setSize(responseItem.data.size)
    } catch (error) {
      console.error(error);
    }
  };

  async function fetchSimilarItems() {
    if (item == null) {
      return;
    }

    try {
      const responseItem = await CustomerService.getListItemPageable(
        1, 
        8,
        '',
        item?.tagMain?.map(tag => tag.id)?.join(","),
        item?.tagSecondary?.map(tag => tag.id)?.join(",")
      );
      setSimilarProduct(responseItem.data.itemList || []);
    } catch (error) {
      console.error(error);
    }
  }

  const getWishlistStatus = async () => {
    try {
      const response = await WishlistService.getWishlistStatus(auth?.user.id, id);
      if (response) {
        const status = response.data.status;
        setBookmarked(status)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const addWishlist = async () => {
    try {
      const response = await WishlistService.addWishlist(auth?.user.id, id);
      if (response) {
        setBookmarked(true)
        setopenWishlistSnack(true)
      }
    } catch (error) {
      console.error(error)
    }
  } 

  const deleteWishlist = async () => {
    try {
      const response = await WishlistService.deleteWishlist(auth?.user.id, id);
      if (response) {
        setBookmarked(false)
      }
    } catch (error) {
      console.error(error)
    }
  } 

  const addItem = async () => {
    if (!auth?.user) {
      navigate("/login");
      return;
    }

    if (!size) {
      setMsgError("Silahkan pilih ukuran terlebih dahulu");
      activateError();
      return;
    }

    const variant = item.variantModels?.find(v => v.id === size)
    if (quantity > variant.quantity) {
      setMsgError("Stok tidak mencukupi");
      activateError();
      return;
    }

    setLoadingAddItem(true);
    try {
      const response = await CartService.addToCart(auth?.user.id, id, quantity, size);
      if (response) {
        setopenSuccessSnack(true);
      }
    } catch (error) {
      console.error(error);
      setMsgError("Tambah item gagal");
      activateError();
    } finally {
      setLoadingAddItem(false);
    }
  };

  const calculateDeliveryFee = async () => {
    if (!validate()) {
      return;
    }

    try {
      setLoadingCalculateFee(true);
      setDeliveryFee(null);
      const response = await CustomerService.getDeliveryFee(item.id, postCode);
      if (response) {
        const data = response.data;
        setDeliveryFee(data.deliveryFee);
      }
    } catch (error) {
      console.error(error);
      const response = error.response;
      if (response.request.status === 404) {
        setErrors({postCode: "Kode pos tidak ditemukan"});
      }
    } finally {
      setLoadingCalculateFee(false);
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    setImageIndex(0);
    fetchItemDetail();
  }, [id]);

  useEffect(() => {
    fetchSimilarItems()
    getWishlistStatus()
  }, [item])

  return (
    <>
      <Navigation />

      <Container component="main" maxWidth="xl" sx={{ paddingBottom: "50px", minHeight: "80vh"}}>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={ShowSuccess}
          autoHideDuration={3000}
          onClose={() => setShowSuccess(false)}
        >
          <SnackbarContent
            sx={{ backgroundColor: "#19B600" }} 
            message={MsgSuccess}/>
        </Snackbar>
  
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={showError}
          autoHideDuration={3000}
          onClose={() => setShowError(false)}
        >
          <SnackbarContent 
            sx={{ backgroundColor: "#E50B0B" }} 
            message={msgError}/>
        </Snackbar>

        <Grid container marginTop={5} columnSpacing={5}>
          <Grid item xs={12} md={6}>
            <Box display="flex" sx={{ flexDirection: {xs: "column", md: "row"}, gap: "20px", alignItems: "stretch" }}>
              <Box 
                display="flex" 
                sx={{ 
                  width: {xs: "100%", md: "15%"}, 
                  flexDirection: {xs: "row", md: "column"},
                  order: {xs: 2, md: 1},
                  gap: "20px",
                  overflow: {xs: "auto", md: "unset"}
                }}
              >
                {item?.images?.map((image, index) => (
                  <Box
                    key={image}
                    sx={{ 
                      borderRadius: "10px", 
                      overflow: "hidden",
                      width: {xs: "25%", md: "100%"},
                      aspectRatio: "1 / 1",
                      cursor: "pointer",
                      border: index === imageIndex ? `2px solid ${orangeHex}` : 'unset',
                      flexShrink: 0
                    }}  
                    onClick={() => setImageIndex(index)}
                  >
                    <img
                      src={`${imageUrl}/${image}`}
                      alt={"text"}
                      width="100%"
                      style={{ aspectRatio: "1 / 1", objectFit: "cover" }}
                      loading="lazy"
                    />
                  </Box>
                ))}
              </Box>
              <Box 
                sx={{ 
                  width: {xs: "100%", md: "85%"} ,
                  order: {xs: 1, md: 2}
                }}
              >
                <Box sx={{ borderRadius: "10px", overflow: "hidden", aspectRatio: "1 / 1", position: "relative" }}>
                  <img
                    src={item?.images ? `${imageUrl}/${item?.images[imageIndex]}` : noImage}
                    alt={"text"}
                    width="100%"
                    style={{ aspectRatio: "1 / 1", objectFit: "cover" }}
                    loading="lazy"
                  />
                  <Box sx={{ position: "relative", bottom: "50px", display: "flex", gap: "10px", justifyContent: "end", paddingRight: "20px" }}>
                    <button 
                      style={qtyBtnStyle} 
                      disabled={imageIndex === 0}
                      onClick={() => setImageIndex(imageIndex - 1)}
                    >
                      <ArrowBackIosIcon sx={{ fontSize: 12 }} />
                    </button>
                    <button 
                      style={qtyBtnStyle} 
                      disabled={imageIndex >= ((item?.images?.length || 1) - 1) }
                      onClick={() => setImageIndex(imageIndex + 1)}
                    >
                      <ArrowForwardIosIcon sx={{ fontSize: 12 }} />
                    </button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container marginTop="16px">
              <Grid item xs={8} md={6} display="flex" alignItems="center">
                {
                  item?.brand && (
                    <Box>
                      <img 
                        src={item?.brand.images == null ? noImage : `${imageUrl}${item.brand.images[0]}`}
                        alt={item?.brand.title}
                        style={{ width: "40%", objectFit: "contain" }}
                      />
                    </Box>
                  )
                }
              </Grid>
            </Grid>
            <Typography variant="h5" fontWeight="600" sx={{ ...headerStyle }}>{item?.name}</Typography>
            <Typography variant="h5" sx={{ ...headerStyle }}>
              <NumericFormat
                value={item?.defaultPrice}
                displayType={"text"}
                thousandSeparator="."
                decimalSeparator=","
                prefix={"Rp. "}
              />
            </Typography>

            <Grid container marginTop="20px" columnSpacing={2}>
              <Grid item xs={12} md={12} marginBottom={1}>
                <InputLabel sx={{ ...textStyle }} id="label-size">Pilih Ukuran</InputLabel>
                <Grid container columnSpacing={1}>
                  {
                    item?.variantModels?.map(variant => (
                        <Grid item xs={2}>
                          <Box 
                            sx={{ borderRadius: "8px", border: variant.id === size ? "1px solid white" : "1px solid #505862", cursor: "pointer", height: "35px" }} 
                            key={variant.id}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            onClick={() => handleChangeSize(variant.id)}
                          >
                            <Typography sx={{ ...textStyle }}>{variant.size}</Typography>
                          </Box>
                        </Grid>
                    ))
                  }
                </Grid>
                {
                  variant && (
                    <Typography sx={{ ...textSmallStyle, marginTop: "10px" }}>Stok: {variant.quantity}</Typography>
                  )
                }
              </Grid>
              <Grid item xs={12} md={12} marginBottom={1} display="flex" justifyContent="space-between">
                <InputLabel sx={{ ...textStyle }} id="label-quantity">Atur Jumlah</InputLabel>
                <Box display="flex" alignItems="center" gap="5px">
                  <button style={qtyBtnStyle} onClick={handleMinusQuantity}>-</button>
                  <TextField
                    sx={{ width: "50px", textAlign: "center", ...colorWhite }}
                    autoComplete="off"
                    value={quantity}
                    min="1"
                    onChange={(e) => handleQuantityChange(e.target.value)}
                    InputProps={{ 
                      style: { height: "30px", fontSize: ".8em", padding: "2px", textAlign: "center", color: "white" }
                    }}
                  />
                  <button style={qtyBtnStyle} onClick={handleAddQuantity}>+</button>
                </Box>
              </Grid>
            </Grid>

            <Grid container marginTop={1}>
              <Grid item xs={12} md={12}>
                <Button 
                  style={btnStyle} 
                  sx={{ ...textStyle }} 
                  startIcon={<ShoppingBasketIcon />} 
                  onClick={addItem}
                >
                  Masukkan ke Keranjang
                </Button>
              </Grid>
              <Grid item xs={12} md={12} marginTop={1}>
                <Button
                  style={{ ...btnStyleOutlined, ...colorWhite }}
                  onClick={handleBookmark}
                  disabled={loadingWishlist}
                  sx={{ ...textStyle }}
                  startIcon={isBookmarked ? <FavoriteIcon sx={{ ...colorWhite }} /> : <FavoriteBorderIcon sx={{ ...colorWhite }} />}
                >
                  Wishlist
                </Button>
              </Grid>
            </Grid>

            <Box marginTop={3}>
              <Typography sx={{ ...textStyle, fontWeight: "bold" }}>
                Cek Ongkir
              </Typography>
              <Typography sx={{ ...textStyle }}>
                Masukkan kode pos untuk mengecek biaya pengiriman:
              </Typography>
              <Grid container marginTop={1} columnSpacing={2}>
                <Grid item xs={12} md={8} marginBottom={1}>
                  <TextField
                    fullWidth
                    autoComplete="off"
                    value={postCode}
                    onChange={(e) => setPostCode(e.target.value)}
                    placeholder="Masukkan kode pos"
                    error={errors?.postCode}
                    helperText={errors?.postCode}
                    FormHelperTextProps={{ sx: { color: "red" } }}
                    sx={{ border: "1px solid #505862", borderRadius: "8px" }}
                    InputProps={{ 
                      style: { height: "40px", padding: "2px", ...textStyle }
                    }}
                    // sx={{  height: "30px", fontSize: ".8em"  }}
                  />
                </Grid>
                <Grid item xs={12} md={4} marginBottom={1} display="flex" alignItems="center">
                  <Button
                    style={{ ...btnStyle }}
                    disabled={loadingCalculateFee}
                    onClick={calculateDeliveryFee}
                  >
                    {loadingCalculateFee ? "Loading" : "Cek Ongkir"}
                  </Button>
                </Grid>
                {
                  deliveryFee !== null && (
                    <Grid item xs={12} marginTop={1}>
                      <Typography sx={{ ...textSmallStyle }}>
                        Ongkos Kirim:&nbsp;
                        <NumericFormat
                          value={deliveryFee}
                          displayType={"text"}
                          thousandSeparator="."
                          decimalSeparator=","
                          prefix={"Rp. "}
                        />
                      </Typography>
                    </Grid>
                  )
                }
              </Grid>
            </Box>

            <Box marginTop={1}>
              <LiqueAccordion title="Deskripsi Produk">
                <Typography sx={{ ...textStyle }}>{item?.description}</Typography>
              </LiqueAccordion>
            </Box>

          </Grid>
        </Grid>
      </Container>

      <div style={{ width: "100%", overflow: "hidden" }}>
        <Container component="main" maxWidth="xl">
          <ItemCarousel title="Kamu Mungkin Juga Suka">
            {
              similarProduct.map(item => (
                <ItemCard key={item.id} item={item} />
              ))
            }
          </ItemCarousel>
        </Container>
      </div>
    
      <Footer />

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={OpenSuccessSnack}
        autoHideDuration={3000}
        onClose={() => setopenSuccessSnack(false)}
      >
        <SnackbarContent 
          sx={{ backgroundColor: "#19B600" }} 
          action={action}
          message="Berhasil menambahkan ke keranjang"/>
      </Snackbar>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={OpenWishlistSnack}
        autoHideDuration={3000}
        onClose={() => setopenWishlistSnack(false)}
      >
        <SnackbarContent 
          sx={{ backgroundColor: "#19B600" }} 
          action={actionWishlist}
          message="Berhasil menambahkan ke wishlist"/>
      </Snackbar>


    </>
  )
};

export default ItemDetailPage2;
