import noImage from "../../src/assets/no-image.png";
import logoDana from "../assets/logoDana.png";
import logoShopeePay from "../assets/logoShopeePay.svg";
import logoOvo from "../assets/logoOvo.png";
import logoLinkAja from "../assets/logoLinkAja.png";
import logoJeniusPay from "../assets/logoJeniusPay.png";
import logoAstraPay from "../assets/logoAstraPay.png";
import logoBca from "../assets/bca.png";
import logoMandiri from "../assets/mandiri.png";
import logoBri from "../assets/bri.svg";
import logoBni from "../assets/bni.svg";
import logoCimb from "../assets/cimb.svg";
import logoPermata from "../assets/permata.svg";
import logoSampoerna from "../assets/sampoerna.svg";
import logoBjb from "../assets/bjb.svg";
import logoBsi from "../assets/bsi.svg";
import logoQris from "../assets/qris.svg";
import logoJnt from "../assets/logoJnt.png";
import logoSmile from "../assets/smile.png";
import EditIcon from '@mui/icons-material/Edit';
import PersonIcon from '@mui/icons-material/Person';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Navigation from "./navigation";
import Footer from "./components/footer/footer";
import {
  Alert,
  Autocomplete,
  Backdrop,
  Box,
  Button,
  Checkbox,
  Container,
  createTheme,
  Fade,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Modal,
  Radio,
  RadioGroup,
  Step,
  StepLabel,
  Stepper,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { useAuth } from "../authContext";
import CustomerService from "../services/customer/customerService";
import { NumericFormat } from "react-number-format";
import { AddressService } from "../services/customer/addressService";
import { QRCodeCanvas } from "qrcode.react";
import { fontSizeText, grayHex, headerStyle, lightgrayHex, styleModal, subHeaderStyle, subHeaderStyleContrast, textSmallStyle, textStyle, textStyleContrast } from "../helper/style";
import LiqueModal from "./components/liqueModal";

const imageUrl = "https://api.liquestore.com/assets/items/"
const theme = createTheme({
  components: {
    MuiStepIcon: {
      styleOverrides: {
        root: {
          "&.Mui-active": {
            color: "#FE8A01",
          },
          "&.Mui-completed": {
            color: "#FE8A01",
          },
        },
      },
    },
  },
});

const btnStyle = {
  borderRadius: "50px",
  width: "100%",
  padding: 10,
  boxShadow: 24,
  color: "white",
  fontWeight: "bold",
  backgroundColor: "black",
  textTransform: "none",
};

const btnStyleDisabled = {
  borderRadius: "50px",
  width: "100%",
  padding: 10,
  boxShadow: 24,
  color: "white",
  fontWeight: "bold",
  backgroundColor: "black",
  textTransform: "none",
  opacity: "0.2"
};

const radioStyle = {
  color: "white",
  '&.Mui-checked': {
    color: "white",
  },
}

const methodItemStyle = {
  marginTop: "24px",
  minHeight: "96px",
  height: "auto",
  width: "99%",
  borderRadius: "20px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  "&:hover": {
    bgcolor: "rgba(0, 0, 0, 0.23)",
  },
}

const CheckoutPage2 = () => {
  const [ShowSuccess, setShowSuccess] = useState(false);
  const [MsgSuccess, setMsgSuccess] = useState("");
  const [showError, setShowError] = useState(false);
  const [msgError, setMsgError] = useState();
  const [errors, setErrors] = useState([]);
  const [ableToProceed, setAbleToProceed] = useState(false);
  
  const [custData, setCustData] = useState(null);
  const [orderData, setOrderData] = useState([]);
  const [addressList, setAddressList] = useState([]);
  const [addressData, setAddressData] = useState(null);
  const [deliveryCourier, setDeliveryCourier] = useState("JNT");
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [paymentChannel, setPaymentChannel] = useState(null);
  const [discountCode, setDiscountCode] = useState("");
  const [loadingAddPayment, setLoadingAddPayment] = useState(false);
  const [paymentInfo, setPaymentInfo] = useState(null);
  const [pollingInterval, setPollingInterval] = useState(null);

  const [addressDialog, setAddressDialog] = useState(false);
  const [ewalletDialog, setEwalletDialog] = useState(false);
  const [virtualAccountDialog, setVirtualAccountDialog] = useState(false);
  const [editAddressId, setEditAddressId] = useState(false);
  const [addAddressDialog, setAddAddressDialog] = useState(false);
  const [informationDialog, setInformationDialog] = useState(false);
  const [successDialog, setSuccessDialog] = useState(false);
  
  const [subtotal, setSubtotal] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [deliveryFee, setDeliveryFee] = useState(null);
  const [adminFee, setAdminFee] = useState(null);

  const [provinces, setProvinces] = useState([]);
  const [cities, setCities] = useState([]);
  const [zipcodes, setZipcodes] = useState([]);
  const [subDistricts, setSubDistricts] = useState([]);
  const [neighborhoods, setNeighborhoods] = useState([]);
  const [selectedProvince, setSelectedProvince] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedSubDistrict, setSelectedSubDistrict] = useState(null);
  const [selectedNeighborhood, setselectedNeighborhood] = useState(null);
  const [selectedZipcode, setSelectedZipcode] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [isDefault, setIsDefault] = useState(false);

  const [openDelete, setOpenDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const { auth } = useAuth();
  const navigate = useNavigate();

  const handleCloseDelete = () => {
    setDeleteId(null);
    setOpenDelete(false);
  };

  const handleOpenDelete = (orderId) => {
    setDeleteId(orderId);
    setOpenDelete(true);
  };

  const activateSuccess = () => {
    setShowSuccess(true);
    setTimeout(() => {
      setShowSuccess(false);
    }, 5000);
  };

  const activateError = () => {
    setShowError(true);
    setTimeout(() => {
      setShowError(false);
    }, 5000);
  };

  const createPayment = async () => {
    try {
      setLoadingAddPayment(true);
      
      const response = await CustomerService.createPayment(
        custData.phonenumber,
        paymentMethod,
        paymentChannel,
        addressData?.id,
        orderData.map(order => order.id)
      );

      if (response) {
        const data = response.data;
        let paymentInformation = null;
        if (paymentMethod === "VIRTUAL_ACCOUNT") {
          paymentInformation = {
            virtualAccountNumber: data.virtualAccountNumber,
            referenceId: data.referenceId,
          };
        } else if (paymentMethod === "EWALLET") {
          paymentInformation = {
            message: "Silakan lanjutkan pembayaran di aplikasi E-Wallet anda",
            referenceId: data.referenceId,
          };
        } else if (paymentMethod === "QR_CODE") {
          paymentInformation = {
            qrCode: data.qrCode,
            referenceId: data.referenceId,
            totalPrice: data.totalPrice,
          };
        } else if (paymentMethod === "COD") {
          paymentInformation = {
            referenceId: data.referenceId,
            totalPrice: data.totalPrice,
            message: "Mohon tunggu sejenak"
          }
        }

        if (paymentInformation !== null) {
          startPolling(data.referenceId);
        }

        setPaymentInfo(paymentInformation);
        setInformationDialog(true);

        if (data.paymentLink) {
          window.open(data.paymentLink, "_blank");
        }
      }
    } catch (error) {
      console.error(error);
      setMsgError("Error while creating payment");
      activateError();
    } finally {
      setLoadingAddPayment(false);
    }
  }

  const startPolling = (referenceId) => {
    const intervalId = setInterval(async () => {
      try {
        const response = await CustomerService.getPaymentStatus(referenceId);
        if (response) {
          const status = response?.data?.status;
  
          if (status === "SUCCEEDED") {
            clearInterval(intervalId); 
            setPollingInterval(null); 
  
            setSuccessDialog(true);
  
            setTimeout(() => {
              setSuccessDialog(false);
              navigate("/customer/profile?page=history");
            }, 5000);
          }
        }
      } catch (error) {
        console.error("Error checking payment status:", error);
      }
    }, 3000); 

    setPollingInterval(intervalId); 
  };

  const getPaymentDataPreview = async () => {
    try {
      const response = await CustomerService.getPaymentDataPreview(
        custData.phonenumber,
        paymentMethod,
        paymentChannel,
        addressData?.id
      )

      if (response) {
        const data = response.data;
        setSubtotal(data.price);
        setDeliveryFee(data.deliveryFee);
        setAdminFee(data.transactionFee);
        setGrandTotal(data.totalPrice);
      }
    } catch (error) {
      console.error(error);
    }
  }

  const fetchDataOrderbyPhone = async () => {
    try {
      const response = await CustomerService.fetchDataOrderByPhone(
        custData.phonenumber
      );
      if (response) {
        const data = response.data.orderList || [];
        const filtered = data.filter((order) => {
          return (
            order.status.key === "WAITING_FOR_PAYMENT"
          );
        })
        const total = filtered.reduce((sum, order) => sum + order.price, 0);

        setOrderData(filtered);
        setSubtotal(total);
        setGrandTotal(total);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchDataOrderByCustomerId = async () => {
    try {
      const response = await CustomerService.fetchDataOrderByCustomerId(
        custData.id
      );
      if (response) {
        const data = response.data;

        if (data) {
          const itemList = data?.itemList
          const total = parseInt(data.price)
  
          setOrderData(itemList);
          setSubtotal(total);
          setGrandTotal(total);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const getPaymentDataPreviewByCustomerId = async () => {
    try {
      const response = await CustomerService.getPaymentDataPreviewByCustomerId(
        custData.id,
        paymentMethod,
        paymentChannel,
        addressData?.id
      )

      if (response) {
        const data = response.data;
        setSubtotal(data.price);
        setDeliveryFee(data.deliveryFee);
        setAdminFee(data.transactionFee);
        setGrandTotal(data.totalPrice);
      }
    } catch (error) {
      console.error(error);
    }
  }

  const fetchDataAddress = async () => {
    try {
      const response = await AddressService.fetchDataAddress(custData.phonenumber);
      if (response) {
        const addressList = response.data.addressList
        setAddressList(addressList);

        if (addressData == null) {
          const defautAddress = addressList.find(address => address.isDefault);
          if (defautAddress) {
            setAddressData(defautAddress);
          }
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchProvinces = async () => {
    try {
      const response = await AddressService.fetchProfinces();
      if (response) {
        setProvinces(response.data.provinceList);
      }
    } catch (error) {
      console.error("There was an error fetching the data!", error);
    }
  };

  const fetchCities = async (provinceId) => {
    try {
      const response = await AddressService.fetchCities(provinceId);
      if (response) {
        setCities(response.data.districtList);
      }
    } catch (error) {
      console.error("There was an error fetching the data!", error);
    }
  };

  const fetchSubDistricts = async (provinceId, cityId) => {
    try {
      const response = await AddressService.fetchSubDistricts(provinceId, cityId);
      if (response) {
        setSubDistricts(response.data.subdistrictList);
      }
    } catch (error) {
      console.error("There was an error fetching the data!", error);
    }
  };

  const fetchNeighborhoods = async (provinceId, cityId, subDistrictId) => {
    try {
      const response = await AddressService.fetchNeighborhoods(provinceId, cityId, subDistrictId);
      if (response) {
        setNeighborhoods(response.data.neighborhoodList);
      }
    } catch (error) {
      console.error("There was an error fetching the data!", error);
    }
  }

  const fetchZipCode = async (provinceId, cityId, subDistrictId) => {
    try {
      const response = await AddressService.fetchZipCode(provinceId, cityId, subDistrictId);
      if (response) {
        setZipcodes(response.data.zipCodeList);
      }
    } catch (error) {
      console.error("There was an error fetching the data!", error);
    }
  }

  const submitAddress = async () => {
    if (!validate()) return;

    try {
      let response = null;
      if (editAddressId != null) {
        response = await AddressService.updateAddress(
          custData.phonenumber,
          editAddressId,
          selectedProvince,
          selectedCity,
          selectedSubDistrict,
          selectedNeighborhood,
          selectedZipcode, 
          selectedAddress,
          isDefault
        )
      } else {
        response = await AddressService.addAddress(
          custData.phonenumber,
          selectedProvince,
          selectedCity,
          selectedSubDistrict,
          selectedNeighborhood,
          selectedZipcode, 
          selectedAddress,
          isDefault
        );

      }

      if (response) {
        fetchDataAddress();

        setAddAddressDialog(false);
        setSelectedAddress(null);
        setSelectedProvince(null);
        setSelectedCity(null);
        setSelectedSubDistrict(null);
        setselectedNeighborhood(null);
        setSelectedZipcode(null);
        setIsDefault(false);
        setEditAddressId(null);
      }
    } catch (error) {
      console.error(error);
      if (editAddressId) {
        setMsgError("Error while updating address");
      } else {
        setMsgError("Error while adding new address");
      }
      activateError();
    }
  }

  useEffect(() => {
    setCustData(auth?.user);
  }, [auth]);

  useEffect(() => {
    if (custData) {
      fetchDataOrderByCustomerId();
      fetchDataAddress();
      fetchProvinces();
    }
  }, [custData]);

  useEffect(() => {
    if (addressData != null || paymentChannel != null) {
      getPaymentDataPreviewByCustomerId();
    }

    if (addressData != null && deliveryCourier != null && paymentChannel != null && paymentMethod != null) {
      setAbleToProceed(true);
    } else {
      setAbleToProceed(false);
    }
  }, [addressData, deliveryCourier, paymentChannel, paymentMethod])

  const handleChooseAddress = (address) => {
    setAddressData(address);
    setAddressDialog(false);
  }
  
  useEffect(() => {
    return () => {
      if (pollingInterval) clearInterval(pollingInterval);
    };
  }, [pollingInterval]);

  const handleOpenAddressDialog = () => {
    setAddressDialog(true);
  }

  const handleCloseAddressDialog = () => {
    setAddressDialog(false);
  }

  const handleOpenAddAddress = async (address = null) => {
    fetchProvinces();
    if (address != null && address !== undefined) {
      setEditAddressId(address.id);
      fetchCities(address.province);
      fetchSubDistricts(address.province, address.district);
      fetchNeighborhoods(address.province, address.district, address.subdistrict);
      fetchZipCode(address.province, address.district, address.subdistrict);

      setSelectedAddress(address.detail);
      setSelectedProvince(address.province);
      setSelectedCity(address.district);
      setSelectedSubDistrict(address.subdistrict);
      setselectedNeighborhood(address.neighborhood);
      setSelectedZipcode(address.zipCode);
      setIsDefault(address.isDefault);
    }

    setAddAddressDialog(true);
  }

  const handleCloseAddAddress = () => {
    setAddAddressDialog(false);
    setEditAddressId(null);
  }

  const setPaymentData = (paymentMethod, paymentChannel) => {
    setPaymentMethod(paymentMethod);
    setPaymentChannel(paymentChannel);
  }
  
  const setEwalletPaymentData = (paymentChannel) => {
    setPaymentMethod("EWALLET");
    setPaymentChannel(paymentChannel);

    setEwalletDialog(false);
  }

  const setVAPaymentData = (paymentChannel) => {
    setPaymentMethod("VIRTUAL_ACCOUNT");
    setPaymentChannel(paymentChannel);

    setVirtualAccountDialog(false);
  }

  const handleCloseInformation = (event, reason) => {
    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
      informationDialog(false);
    }
  }

  const validate = () => {
    let tempErrors = {};

    if (!selectedAddress) {
      tempErrors.address = "address harus diisi";
    } else if (selectedAddress.length > 255) {
      tempErrors.address = "address maksimal 255 karakter";
    }

    if (!selectedCity) {
      tempErrors.city = "Kota harus diisi";
    }

    if (!selectedProvince) {
      tempErrors.state = "Provinsi harus diisi";
    }

    if (!selectedZipcode) {
      tempErrors.zipcode = "zipcode harus diisi";
    } else if (selectedZipcode.length > 6) {
      tempErrors.zipcode = "zipcode maksimal 6 karakter";
    }

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  return (
    <>
      <Navigation />

      <Container component="main" maxWidth="xl" sx={{ marginBottom: "50px" }}>
        {ShowSuccess && (
          <Alert variant="filled" severity="success" style={{ marginTop: 20 }}>
            {MsgSuccess}
          </Alert>
        )}
        {showError && (
          <Alert variant="filled" severity="error" style={{ marginTop: 20 }}>
            {msgError}
          </Alert>
        )}

        <Box marginTop={5}>
          <Typography sx={{ ...headerStyle }} fontWeight="bold" textTransform="uppercase">
            Checkout
          </Typography>

          <Grid container marginTop={2} columnSpacing={6} position="relative">
            <Grid item xs={12} md={6} lg={8} marginBottom={2}>
              <Box paddingX={3} paddingY={2} marginTop={2} onClick={handleOpenAddressDialog} 
                sx={{ cursor: "pointer", background: grayHex, borderRadius: "16px"  }}
                display="flex" justifyContent="center" flexDirection="column">
                <Box marginBottom={2}>
                  <Typography sx={{ ...subHeaderStyle, fontWeight: "bold" }}>
                    Alamat Pengiriman
                  </Typography>
                </Box>
                {
                  addressData == null ? (
                    <Box>
                      <Typography sx={{ ...textStyle }}  fontWeight="bold">
                        Pilih alamat pengiriman untuk melanjutkan
                      </Typography>
                    </Box>
                  ) : (
                    <>
                      <Box>
                        <Typography sx={{ ...textStyle, fontWeight: "bold" }}>
                          {addressData.detail}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography sx={{ ...textStyle }}>
                          {addressData.province}, {addressData.district},{" "}
                          {addressData.subdistrict}, {addressData.neighborhood},{" "}
                          {addressData.zipCode}
                        </Typography>
                      </Box>
                    </>
                  )
                }
              </Box>

              <Box paddingX={3} paddingY={2} marginTop={2}
                sx={{ background: grayHex, borderRadius: "16px"  }}
                display="flex" justifyContent="center" flexDirection="column">
                <Box marginBottom={2}>
                  <Typography sx={{ ...subHeaderStyle, fontWeight: "bold" }}>
                    Detail Produk
                  </Typography>
                </Box>
                {
                  orderData.map((order, index) => (
                    <React.Fragment key={index}>
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={12} md={2}>
                            <Box sx={{ 
                              width: "100%",
                              maxWidth: "80px",
                              aspectRatio: "1 / 1",
                              borderRadius: "16px",
                              overflow: "hidden"
                            }}>
                              <img
                                src={
                                  order.item.files?.length <= 0 || order.item.files == null
                                    ? noImage
                                    : `${imageUrl}${order.item.files[0]}`
                                }
                                alt={order.item.name}
                                style={{
                                  width: "100%",
                                  objectFit: "cover",
                                  marginRight: "3%"
                                }}
                              ></img>
                            </Box>
                          </Grid>
      
                          <Grid item xs={12} md={6}>
                            {
                              order.item.brand && (
                                <>
                                  <img 
                                    src={order.item.brand?.images == null ? noImage : `${imageUrl}${order.item.brand?.images[0]}`}
                                    alt={order.item.brand.title}
                                    style={{ width: "30%", aspectRatio: "6 / 2", objectFit: "contain" }}
                                  />
                                </>
                              )
                            }
                            <Typography sx={{ ...textStyle }}>
                              {order.item?.name}, Size: {order.item?.variant?.size}
                            </Typography>
                          </Grid>
      
                          <Grid item xs={12} md={4} display="flex" alignItems="center">
                            <Box>
                              <Typography sx={{ ...textSmallStyle }}>
                                Total
                              </Typography>
                              <Typography sx={{ ...textStyle, fontWeight: "bold" }}>
                               {order.quantity} x {" "}
                                <NumericFormat
                                  value={order.item.customdefaultprice}
                                  displayType={"text"}
                                  thousandSeparator="."
                                  decimalSeparator=","
                                  prefix={"RP "}
                                />
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  ))
                }
              </Box>
              
              <Box paddingX={3} paddingY={2} marginTop={2}
                sx={{ background: grayHex, borderRadius: "16px"  }}
                display="flex" justifyContent="center" flexDirection="column">
                <Box marginBottom={2}>
                  <Typography sx={{ ...subHeaderStyle, fontWeight: "bold" }}>
                    Metode Pengiriman
                  </Typography>
                </Box>
                <Box item xs={12} sx={{
                  border: "1px solid white",
                  borderTopLeftRadius: "15px",
                  borderTopRightRadius: "15px",
                  display: "flex",
                  alignItems: "center",
                  padding: "15px",
                  justifyContent: "space-between",
                  cursor: "pointer", 
                }}>
                  <Typography sx={{ ...textStyle }}>Reguler</Typography>
                  <ExpandMoreIcon sx={{ color: "white" }} />
                </Box>
                <Box item xs={12} sx={{
                  border: "1px solid white",
                  borderBottomLeftRadius: "15px",
                  borderBottomRightRadius: "15px",
                  borderTop: "unset",
                  padding: "15px",
                  cursor: "pointer", 
                }}>
                  <Box sx={{ 
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between" 
                  }}>
                    <Typography sx={{ ...textStyle }}>
                      J&T (
                        {
                          deliveryFee === null ? (<i>Belum Terhitung</i>) : (
                            <NumericFormat value={deliveryFee} displayType={"text"}
                              thousandSeparator="." decimalSeparator="," prefix={"Rp. "}/>
                          )
                        }
                      )
                    </Typography>
                    <ExpandMoreIcon sx={{ color: "white" }} />
                  </Box>
                </Box>
              </Box>

              <Box paddingX={3} paddingY={2} marginTop={2} 
                sx={{ background: grayHex, borderRadius: "16px"  }}
                display="flex" justifyContent="center" flexDirection="column">
                <Box marginBottom={2}>
                  <Typography sx={{ ...subHeaderStyle, fontWeight: "bold" }}>
                    Metode Pembayaran
                  </Typography>
                  <Grid container marginTop={3}>
                    <Grid item xs={12}>
                      <Box onClick={() => setEwalletDialog(true)} sx={{cursor: "pointer"}}>
                        <Grid container alignItems="center">
                          <Grid item xs={2} md={1}>
                            <Radio sx={radioStyle} disabled checked={paymentMethod === "EWALLET"}/>
                          </Grid>
                          <Grid item xs={4} md={3} sx={{ ...textStyle }}>
                            E-Wallet
                          </Grid>
                          <Grid item xs={6} md={8} sx={{ display: "flex", alignItems: "center", justifyContent: "end", flexWrap: "wrap" }}>
                            <img src={logoDana} alt="Dana Logo" width={60} style={{ paddingRight: "10px" }} />
                            <img src={logoOvo} alt="OVO Logo" width={60} style={{ paddingRight: "10px" }} />
                          </Grid>
                        </Grid>
                      </Box>

                      <Box onClick={() => setVirtualAccountDialog(true)} sx={{cursor: "pointer"}}>
                        <Grid container alignItems="center">
                          <Grid item xs={2} md={1}>
                            <Radio sx={radioStyle} disabled checked={paymentMethod === "VIRTUAL_ACCOUNT"}/>
                          </Grid>
                          <Grid item xs={4} md={3} sx={{ ...textStyle }}>
                            Virtual Account
                          </Grid>
                          <Grid item xs={6} md={8} sx={{ display: "flex", alignItems: "center", justifyContent: "end", flexWrap: "wrap" }}>
                            <img src={logoMandiri} alt="Mandiri Logo" width={45} style={{ paddingRight: "10px" }}/>
                            <img src={logoBri} alt="BRI Logo" width={45} style={{ paddingRight: "10px" }}/>
                            <img src={logoBni} alt="BNI Logo" width={45} style={{ paddingRight: "10px" }} />
                            <img src={logoCimb} alt="CIMB Logo" width={45} style={{ paddingRight: "10px" }} />
                            <img src={logoPermata} alt="Permata Logo" width={45} style={{ paddingRight: "10px" }} />
                            <img src={logoSampoerna} alt="Sampoerna Logo" width={45} style={{ paddingRight: "10px" }} />
                            <img src={logoBjb} alt="BJB Logo" width={45} style={{ paddingRight: "10px" }} />
                            <img src={logoBsi} alt="BSI Logo" width={45} style={{ paddingRight: "10px" }} />
                          </Grid>
                        </Grid>
                      </Box>

                      <Box onClick={() => setPaymentData("QR_CODE", "QRIS")} sx={{cursor: "pointer"}}>
                        <Grid container alignItems="center">
                          <Grid item xs={2} md={1}>
                            <Radio sx={radioStyle} disabled checked={paymentMethod === "QR_CODE"}/>
                          </Grid>
                          <Grid item xs={4} md={3} sx={{ ...textStyle }}>
                            QRIS
                          </Grid>
                          <Grid item xs={6} md={8} sx={{ display: "flex", alignItems: "center", justifyContent: "end", flexWrap: "wrap" }}>
                            <img src={logoQris} alt="QRIS Logo" width={45} style={{ paddingRight: "10px" }} />
                          </Grid>
                        </Grid>
                      </Box>

                      <Box onClick={() => setPaymentData("COD", "COD")} sx={{cursor: "pointer"}}>
                        <Grid container alignItems="center">
                          <Grid item xs={2} md={1}>
                            <Radio sx={radioStyle} disabled checked={paymentMethod === "COD"}/>
                          </Grid>
                          <Grid item xs={4} md={3} sx={{ ...textStyle }}>
                            Cash on Delivery (COD)
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} md={6} lg={4} position="sticky" top="100px" alignSelf="flex-start" marginBottom={2}>
              <Box padding={2} sx={{ background: "white", borderRadius: "15px" }}>
                <Box marginBottom={1}>
                  <Typography sx={{ ...subHeaderStyleContrast, fontWeight: "bold" }}>Ringkasan Pesanan</Typography>
                </Box>
                <Grid container marginBottom={1}>
                  <Grid item xs={7}>
                    <Typography sx={{ ...textStyleContrast }}>Total Harga Produk</Typography>
                  </Grid>
                  <Grid item xs={5} textAlign="end">
                    <Typography sx={{ ...textStyleContrast }}>
                      {/* {subtotal} */}
                      <NumericFormat value={subtotal} displayType={"text"}
                        thousandSeparator="." decimalSeparator="," prefix={"Rp. "}/>
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container marginBottom={1}>
                  <Grid item xs={7}>
                    <Typography sx={{ ...textStyleContrast }}>Total Ongkos Kirim</Typography>
                  </Grid>
                  <Grid item xs={5} textAlign="end">
                    <Typography sx={{ ...textStyleContrast }}>
                      {
                        deliveryFee === null ? (<i>Belum Terhitung</i>) : (
                          // {deliveryFee}
                          <NumericFormat value={deliveryFee} displayType={"text"}
                            thousandSeparator="." decimalSeparator="," prefix={"Rp. "}/>
                        )
                      }
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container marginBottom={1}>
                  <Grid item xs={7}>
                    <Typography sx={{ ...textStyleContrast }}>Biaya Admin {paymentChannel ? "(" + paymentChannel + (paymentMethod === "VIRTUAL_ACCOUNT" ? " VA)" : ")") : ""}</Typography>
                  </Grid>
                  <Grid item xs={5} textAlign="end">
                    <Typography sx={{ ...textStyleContrast }}>
                      {
                        adminFee === null ? (<i>Belum Terhitung</i>) : (
                          <NumericFormat value={adminFee} displayType={"text"}
                            thousandSeparator="." decimalSeparator="," prefix={"Rp. "}/>
                        )
                      }
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container sx={{ height: "50px" }} marginBottom={1} alignItems="center">
                  <Grid item xs={7}>
                    <Typography sx={{ ...textStyleContrast }}>Total</Typography>
                  </Grid>
                  <Grid item xs={5} textAlign="end">
                    <Typography sx={{ ...textStyleContrast }} fontWeight="bold">
                      <NumericFormat value={grandTotal} displayType={"text"}
                        thousandSeparator="." decimalSeparator="," prefix={"Rp. "}/>
                    </Typography>
                  </Grid>
                </Grid>

                <Box marginTop={3}>
                  <Typography sx={{ ...textStyleContrast }}>Masukkan Kode Diskon</Typography>
                  <Grid container marginTop={2}>
                    <Grid item xs={9}>
                      <TextField
                        fullWidth
                        autoComplete="off"
                        value={discountCode}
                        onChange={(e) => setDiscountCode(e.target.value)}
                        placeholder="Kode Diskon"
                        sx={{ borderTopLeftRadius: "12px", borderBottomLeftRadius: "12px", border: ".5px solid #505862" }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Button sx={{ 
                        borderTopLeftRadius: "0",
                        borderBottomLeftRadius: "0",
                        borderTopRightRadius: "12px", 
                        borderBottomRightRadius: "12px",
                        color: "white",
                        fontWeight: "bold",
                        backgroundColor: "black",
                        textTransform: "none",
                        height: "59px",
                        width: "100%",
                      }}>
                        Gunakan
                      </Button>
                    </Grid>
                  </Grid>
                </Box>

                <Box marginTop={5}>
                  <Button style={ableToProceed ? btnStyle : btnStyleDisabled} disabled={loadingAddPayment}
                    onClick={createPayment}
                  >
                    Buat Pesanan
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
          
        </Box>
      </Container>

      <Footer />

      {/* MODALS */}
      <LiqueModal open={addressDialog} onClose={handleCloseAddressDialog}>
        {
          addressList.length > 0 ? (
            <Box>
              {
                addressList.map(address => (
                  <Box position="relative"> 
                    <Box 
                      key={address.id}
                      sx={{
                        backgroundColor: "black",
                        color: "white",
                        borderRadius: "12px",
                        padding: 3,
                        marginY: 2,
                      }}
                      onClick={() => handleChooseAddress(address)}
                    >
                      <Grid container spacing={2} textAlign={"left"} justifyContent="space-between">
                        
                        <Grid item xs={8}>
                          <Grid container>
                            <Grid item xs={12}>
                              <Typography
                                component="h6"
                                sx={{ fontWeight: "600" }}
                              >
                                {address.detail}
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography component="h6">
                                {address.province}, {address.district},{" "}
                                {address.subdistrict}, {address.neighborhood},{" "}
                                {address.zipCode}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>

                    
                    <IconButton sx={{ color: "white", position: "absolute", right: "20px", top: "30%" }} onClick={() => handleOpenAddAddress(address)}>
                      <EditIcon />
                    </IconButton>
                  </Box>
                ))
              }
              <Box>
                <Grid container spacing={2}>
                  <Grid item xs={12} textAlign="center">
                    <Button
                      onClick={handleOpenAddAddress}
                      style={btnStyle}
                    >
                      New Address
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          ) : (
            <Box>
              <img src={logoSmile} alt="Smile Logo" width={100} />
              <Typography
                id="spring-modal-title"
                variant="h6"
                component="h3"
              >
                You haven't added any address yet.
              </Typography>
              <Box sx={{ mt: 2 }} textAlign="center">
                <Button
                  variant="contained"
                  onClick={handleOpenAddAddress}
                  style={btnStyle}
                >
                  Add Your Location
                </Button>
              </Box>
            </Box>
          )
        }
      </LiqueModal>

      <LiqueModal open={addAddressDialog} onClose={handleCloseAddAddress}>
        <form>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography sx={{ ...fontSizeText }}>Alamat *</Typography>
              <TextField
                fullWidth
                autoComplete="off"
                value={selectedAddress}
                error={!!errors.address}
                helperText={errors.address}
                FormHelperTextProps={{ sx: { color: "red" } }}
                onChange={(e) => setSelectedAddress(e.target.value)}
                placeholder="Jl. Melati Mas..." // Add this line
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography sx={{ ...fontSizeText }}>Provinsi *</Typography>
              <Autocomplete
                options={provinces} 
                getOptionLabel={(option) => option} 
                value={selectedProvince}
                onChange={(event, newValue) => {
                  setSelectedCity(null);
                  setSelectedSubDistrict(null);
                  setselectedNeighborhood(null);
                  setSelectedZipcode(null);

                  fetchCities(newValue);
                  setSelectedProvince(newValue);
                }}
                helperText={errors.selectedProvince}
                FormHelperTextProps={{ sx: { color: "red" } }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="normal"
                    fullWidth
                    sx={{ 
                      '& .MuiInputBase-input': {
                        ...fontSizeText
                      },
                    }} 
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography sx={{ ...fontSizeText }}>Kabupaten/Kota *</Typography>
              <Autocomplete
                options={cities}
                getOptionLabel={(option) => option}
                value={selectedCity}
                onChange={(event, newValue) => {
                  setSelectedSubDistrict(null);
                  setselectedNeighborhood(null);
                  setSelectedZipcode(null);

                  fetchSubDistricts(selectedProvince, newValue);
                  setSelectedCity(newValue);
                }}
                helperText={errors.selectedCity}
                FormHelperTextProps={{ sx: { color: "red" } }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="normal"
                    fullWidth
                    sx={{ 
                      '& .MuiInputBase-input': {
                        ...fontSizeText
                      },
                    }} 
                  />
                )}
                disabled={selectedProvince === null}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography sx={{ ...fontSizeText }}>Kecamatan *</Typography>
              <Autocomplete
                options={subDistricts} 
                getOptionLabel={(option) => option} 
                value={selectedSubDistrict}
                onChange={(event, newValue) => {
                  setselectedNeighborhood(null);
                  setSelectedZipcode(null);

                  fetchNeighborhoods(selectedProvince, selectedCity, newValue);
                  fetchZipCode(selectedProvince, selectedCity, newValue)
                  setSelectedSubDistrict(newValue);
                }}
                helperText={errors.selectedProvince}
                FormHelperTextProps={{ sx: { color: "red" } }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="normal"
                    fullWidth
                    sx={{ 
                      '& .MuiInputBase-input': {
                        ...fontSizeText
                      },
                    }} 
                  />
                )}
                disabled={selectedCity === null}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography sx={{ ...fontSizeText }}>Kelurahan *</Typography>
              <Autocomplete
                options={neighborhoods}
                getOptionLabel={(option) => option}
                value={selectedNeighborhood}
                onChange={(event, newValue) =>
                  setselectedNeighborhood(newValue)
                }
                helperText={errors.selectedCity}
                FormHelperTextProps={{ sx: { color: "red" } }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="normal"
                    fullWidth
                    sx={{ 
                      '& .MuiInputBase-input': {
                        ...fontSizeText
                      },
                    }} 
                  />
                )}
                disabled={selectedSubDistrict === null}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography sx={{ ...fontSizeText }}>Kode Pos *</Typography>
              <Autocomplete
                options={zipcodes}
                getOptionLabel={(option) => option}
                value={selectedZipcode}
                onChange={(event, newValue) =>
                  setSelectedZipcode(newValue)
                }
                helperText={errors.selectedCity}
                FormHelperTextProps={{ sx: { color: "red" } }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="normal"
                    fullWidth
                    sx={{ 
                      '& .MuiInputBase-input': {
                        ...fontSizeText
                      },
                    }} 
                  />
                )}
                disabled={selectedSubDistrict === null}
              />
            </Grid>
            <Grid item xs={12} md={6} alignSelf="center">
              <FormControlLabel control={<Checkbox checked={isDefault} onChange={(_event, checked) => setIsDefault(checked)}/>} 
                label="Tetapkan Sebagai Default" sx={{ '& .MuiTypography-root': {...fontSizeText} }}/>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                onClick={submitAddress}
                fullWidth
                style={{
                  backgroundColor: "black",
                  color: "white",
                }}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </LiqueModal>

      <LiqueModal open={ewalletDialog} onClose={() => setEwalletDialog(false)}>
        <Box onClick={() => setEwalletPaymentData("DANA")}
          sx={{
            ...methodItemStyle,
            border:
              paymentChannel === "DANA"
                ? "4px solid rgb(254, 138, 1)"
                : "2px solid black",
          }}
        >
          <Grid container sx={{ textAlign: "left", padding: "20px", }}>
            <Grid item xs={8} sx={{ display: "flex", alignItems: "center" }}>
              DANA
            </Grid>
            <Grid item xs={4} sx={{ display: "flex", alignItems: "center" }}>
              <img src={logoDana} alt="Dana Logo"
                width={120} style={{ transition: "background-color 0.3s" }}/>
            </Grid>
          </Grid>
        </Box>

        <Box onClick={() => setEwalletPaymentData("OVO")}
          sx={{
            ...methodItemStyle,
            border:
              paymentChannel === "OVO"
                ? "4px solid rgb(254, 138, 1)"
                : "2px solid black",
          }}
        >
          <Grid container sx={{ textAlign: "left", padding: "20px", }}>
            <Grid item xs={8} sx={{ display: "flex", alignItems: "center" }}>
              OVO
            </Grid>
            <Grid item xs={4} sx={{ display: "flex", alignItems: "center" }}>
              <img src={logoOvo} alt="OVO Logo"
                width={120} style={{ transition: "background-color 0.3s" }}/>
            </Grid>
          </Grid>
        </Box>
      </LiqueModal>

      <LiqueModal open={virtualAccountDialog} onClose={() => setVirtualAccountDialog(false)}>
        <Box onClick={() => setVAPaymentData("MANDIRI")}
          sx={{
            ...methodItemStyle,
            border:
              paymentChannel === "MANDIRI"
                ? "4px solid rgb(254, 138, 1)"
                : "2px solid black",
          }}
        >
          <Grid container sx={{ textAlign: "left", padding: "20px", }}>
            <Grid item xs={8} sx={{ display: "flex", alignItems: "center" }}>
              Mandiri Virtual Account
            </Grid>
            <Grid item xs={4} sx={{ display: "flex", alignItems: "center" }}>
              <img src={logoMandiri} alt="Mandiri Logo"
                width={120} style={{ transition: "background-color 0.3s" }}/>
            </Grid>
          </Grid>
        </Box>

        <Box onClick={() => setVAPaymentData("BRI")}
          sx={{
            ...methodItemStyle,
            border:
              paymentChannel === "BRI"
                ? "4px solid rgb(254, 138, 1)"
                : "2px solid black",
          }}
        >
          <Grid container sx={{ textAlign: "left", padding: "20px", }}>
            <Grid item xs={8} sx={{ display: "flex", alignItems: "center" }}>
              BRI Virtual Account
            </Grid>
            <Grid item xs={4} sx={{ display: "flex", alignItems: "center" }}>
              <img src={logoBri} alt="BRI Logo"
                width={120} style={{ transition: "background-color 0.3s" }}/>
            </Grid>
          </Grid>
        </Box>

        <Box onClick={() => setVAPaymentData("BNI")}
          sx={{
            ...methodItemStyle,
            border:
              paymentChannel === "BNI"
                ? "4px solid rgb(254, 138, 1)"
                : "2px solid black",
          }}
        >
          <Grid container sx={{ textAlign: "left", padding: "20px", }}>
            <Grid item xs={8} sx={{ display: "flex", alignItems: "center" }}>
              BNI Virtual Account
            </Grid>
            <Grid item xs={4} sx={{ display: "flex", alignItems: "center" }}>
              <img src={logoBni} alt="BNI Logo"
                width={120} style={{ transition: "background-color 0.3s" }}/>
            </Grid>
          </Grid>
        </Box>

        <Box onClick={() => setVAPaymentData("CIMB")}
          sx={{
            ...methodItemStyle,
            border:
              paymentChannel === "CIMB"
                ? "4px solid rgb(254, 138, 1)"
                : "2px solid black",
          }}
        >
          <Grid container sx={{ textAlign: "left", padding: "20px", }}>
            <Grid item xs={8} sx={{ display: "flex", alignItems: "center" }}>
              CIMB Virtual Account
            </Grid>
            <Grid item xs={4} sx={{ display: "flex", alignItems: "center" }}>
              <img src={logoCimb} alt="CIMB Logo"
                width={120} style={{ transition: "background-color 0.3s" }}/>
            </Grid>
          </Grid>
        </Box>

        <Box onClick={() => setVAPaymentData("PERMATA")}
          sx={{
            ...methodItemStyle,
            border:
              paymentChannel === "PERMATA"
                ? "4px solid rgb(254, 138, 1)"
                : "2px solid black",
          }}
        >
          <Grid container sx={{ textAlign: "left", padding: "20px", }}>
            <Grid item xs={8} sx={{ display: "flex", alignItems: "center" }}>
              Permata Virtual Account
            </Grid>
            <Grid item xs={4} sx={{ display: "flex", alignItems: "center" }}>
              <img src={logoPermata} alt="PERMATA Logo"
                width={120} style={{ transition: "background-color 0.3s" }}/>
            </Grid>
          </Grid>
        </Box>

        <Box onClick={() => setVAPaymentData("SAHABAT_SAMPOERNA")}
          sx={{
            ...methodItemStyle,
            border:
              paymentChannel === "SAHABAT_SAMPOERNA"
                ? "4px solid rgb(254, 138, 1)"
                : "2px solid black",
          }}
        >
          <Grid container sx={{ textAlign: "left", padding: "20px", }}>
            <Grid item xs={8} sx={{ display: "flex", alignItems: "center" }}>
              Sampoerna Virtual Account
            </Grid>
            <Grid item xs={4} sx={{ display: "flex", alignItems: "center" }}>
              <img src={logoSampoerna} alt="SAHABAT_SAMPOERNA Logo"
                width={120} style={{ transition: "background-color 0.3s" }}/>
            </Grid>
          </Grid>
        </Box>

        <Box onClick={() => setVAPaymentData("BJB")}
          sx={{
            ...methodItemStyle,
            border:
              paymentChannel === "BJB"
                ? "4px solid rgb(254, 138, 1)"
                : "2px solid black",
          }}
        >
          <Grid container sx={{ textAlign: "left", padding: "20px", }}>
            <Grid item xs={8} sx={{ display: "flex", alignItems: "center" }}>
              BJB Virtual Account
            </Grid>
            <Grid item xs={4} sx={{ display: "flex", alignItems: "center" }}>
              <img src={logoBjb} alt="BJB Logo"
                width={120} style={{ transition: "background-color 0.3s" }}/>
            </Grid>
          </Grid>
        </Box>

        <Box onClick={() => setVAPaymentData("BSI")}
          sx={{
            ...methodItemStyle,
            border:
              paymentChannel === "BSI"
                ? "4px solid rgb(254, 138, 1)"
                : "2px solid black",
          }}
        >
          <Grid container sx={{ textAlign: "left", padding: "20px", }}>
            <Grid item xs={8} sx={{ display: "flex", alignItems: "center" }}>
              BSI Virtual Account
            </Grid>
            <Grid item xs={4} sx={{ display: "flex", alignItems: "center" }}>
              <img src={logoBsi} alt="BSI Logo"
                width={120} style={{ transition: "background-color 0.3s" }}/>
            </Grid>
          </Grid>
        </Box>
      </LiqueModal>

      <Modal
        open={successDialog}
        disableEscapeKeyDown
        aria-labelledby="success-modal-title"
        aria-describedby="success-modal-description"
        BackdropProps={{ style: { pointerEvents: "none" } }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            textAlign: "center",
          }}
        >
          <Typography id="success-modal-title" variant="h6">
            Payment Successful!
          </Typography>
          <Typography id="success-modal-description" sx={{ mt: 2 }}>
            You will be redirected to the homepage shortly.
          </Typography>
        </Box>
      </Modal>

      <LiqueModal open={informationDialog} onClose={handleCloseInformation}>
        <Box
          sx={{
            width: "100%"
          }}
        >
          {paymentMethod === "VIRTUAL_ACCOUNT" ? (
            <>
              <Typography variant="h5" sx={{ textAlign: "left" }}>
                No. Virtual Account:{" "} {paymentInfo?.virtualAccountNumber}
              </Typography>
              <Typography variant="h5" sx={{ textAlign: "left" }}>
                Total yang harus dibayarkan:
                <NumericFormat
                  value={grandTotal}
                  displayType={"text"}
                  thousandSeparator="."
                  decimalSeparator=","
                  prefix={" Rp. "}
                />
              </Typography>
            </>
          ) : (
            <>
              <Typography variant="h5" sx={{ textAlign: "left" }}>
                {paymentInfo?.message}
              </Typography>
              <Typography variant="h5" sx={{ textAlign: "left" }}>
                Total yang harus dibayarkan:
                <NumericFormat
                  value={grandTotal}
                  displayType={"text"}
                  thousandSeparator="."
                  decimalSeparator=","
                  prefix={" Rp. "}
                />
              </Typography>

              {
                paymentInfo?.qrCode && (
                  <Box
                    sx={{
                      marginTop: "20px",
                      textAlign: "center",
                      paddingRight: "125px",
                    }}
                  >
                    <QRCodeCanvas value={paymentInfo.qrCode} size={256} />
                    <Typography variant="body1" sx={{ marginTop: "10px" }}>
                      Scan QR code ini untuk membayar
                    </Typography>
                  </Box>
                ) 
              }
            </>
          )}
          <Box marginTop={3}>
            <Button style={btnStyle} onClick={() => navigate("/customer/profile?page=history")} >
              Cek Status Bayar
            </Button>
          </Box>
        </Box>
      </LiqueModal>
    </>
  );
};

export default CheckoutPage2;
