import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from "@mui/material/Button";
import axios from "axios";
import {
  Alert,
  Box,
  Grid,
  InputAdornment,
  FormHelperText,
} from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./register.css";
import logoShow from "./assets/EyeClose.svg";
import logoHide from "./assets/EyeOpen.svg";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import calendarLogo from "./assets/calendar-icon.png";
import calendarLogoBlack from "./assets/calendar-icon-black.png";
import Navigation from "./customer/navigation";
import { blackHex, btnStyleText, fontSizeText, fontSizeTextSmall, grayHex, lightergrayHex, passwordTextfieldStyle, passwordToggleStyle, subHeaderStyle, textfieldStyle, textSmallStyle, textStyle } from "./helper/style";
import logoToko from "./assets/liqueLogo.png";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const btnRegister = {
  marginTop: "20px",
  justifyContent: "center",
  borderRadius: "50px",
  backgroundColor: "white",
  color: "black",
  width: "100%",
  maxWidth: "100%"
};

function RegisterPage() {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const navigate = useNavigate();
  const [param, setParam] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  // const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  // const [usernameIG, setUsernameIG] = useState('');
  const [phonenumber, setPhonenumber] = useState("");
  const [birthdate, setBirthdate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(
    birthdate ? new Date(birthdate) : null
  );
  const [konfirmasiPassword, setKonfirmasiPassword] = useState("");
  const [showError, setShowError] = useState(false);
  const [msgError, setMsgError] = useState();
  const [errors, setErrors] = useState({});
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const orderidFromQuery = params.get("orderid");
  console.log(orderidFromQuery);

  const [showPassword, setShowPassword] = React.useState(false);
  const [showKonfirmasiPassword, setShowKonfirmasiPassword] =
    React.useState(false);

  const handleClickShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleClickShowKonfirmasiPassword = () => {
    setShowKonfirmasiPassword((show) => !show);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setBirthdate(date ? date.toISOString().split("T")[0] : ""); // Adjust format as needed
  };

  const handleLogin = () => {
    if (param) {
      navigate(`/login?orderid=${orderidFromQuery}`);
    } else {
      navigate(`/login`)
    }
  }

  const validate = () => {
    let tempErrors = {};

    // if (!name) {
    //   tempErrors.name = "nama harus diisi";
    // } else if (name.length > 255) {
    //   tempErrors.name = "nama maksimal berjumlah 255 karakter";
    // } else if (typeof name !== "string") {
    //   tempErrors.name = "nama harus berisikan huruf";
    // }
    if (!username) {
      tempErrors.username = "Username harus diisi";
    } else if (username.length > 50) {
      tempErrors.username = "Username maksimal berjumlah 50 karakter";
    }
    if (!password) {
      tempErrors.password = "Password harus diisi";
    } else if (password.length > 255) {
      tempErrors.password = "Password maksimal berjumlah 255 karakter";
    }
    if (!konfirmasiPassword) {
      tempErrors.konfirmasiPassword = "Konfirmasi Password harus diisi";
    } else if (konfirmasiPassword.length > 255) {
      tempErrors.konfirmasiPassword =
        "Konfirmasi Password maksimal berjumlah 255 karakter";
    } else if (password !== konfirmasiPassword) {
      tempErrors.konfirmasiPassword =
        "Konfirmasi Password harus sama dengan password";
    }
    if (!email) {
      tempErrors.email = "Email harus diisi";
    } else if (email.length > 255) {
      tempErrors.email = "Email barang maksimal berjumlah 255 karakter";
    }
    if (!birthdate) {
      tempErrors.birthdate = "Tanggal lahir harus diisi";
    }
    // if (!usernameIG){
    //   tempErrors.usernameIG = 'usernameIG harus diisi';
    // }
    // else if (usernameIG.length > 50) {
    //   tempErrors.usernameIG = 'usernameIG barang maksimal berjumlah 50 karakter';
    // }
    if (!phonenumber) {
      tempErrors.phonenumber = "Phonenumber harus diisi";
    } else if (phonenumber.length < 10 || phonenumber.length > 12) {
      tempErrors.phonenumber = "Phonenumber harus berjumlah antara 10-12 digit";
    }

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      try {
        const date = `${birthdate.getFullYear()}-${birthdate.getMonth() + 1}-${birthdate.getDate()}`;

        const formData = new FormData();
        formData.append("username", username);
        formData.append("password", password);
        // formData.append("name", name);
        formData.append("email", email);
        // formData.append('usernameIG', usernameIG);
        formData.append("phonenumber", phonenumber);
        formData.append("birthdate", date);
        console.log([...formData]);

        const response = await axios.post(`${backendUrl}/register`, formData);
        console.log(response.data);

        setUsername("");
        setPassword("");
        // setName("");
        setEmail("");
        setBirthdate("");
        setPhonenumber("");
        // setUsernameIG('');
        setKonfirmasiPassword("");

        localStorage.setItem("berhasilRegister", response.data.message);
        if (orderidFromQuery == null) {
          navigate("/login");
        } else {
          navigate(`/login?orderid=${orderidFromQuery}`);
        }
      } catch (error) {
        console.log(error);
        setShowError(true);
        setMsgError(error.response.data.message);
        setTimeout(() => {
          setShowError(false);
        }, 5000);
      }
    }
  };

  return (
    <>
      <Container component="main" maxWidth="md" sx={{ marginY: "50px" }}>
        {showError && (
          <Alert variant="filled" severity="error" sx={{ marginBottom: 5 }}>
            {msgError}
          </Alert>
        )}

        <Box>
          <Grid container spacing={1} marginTop={1} columnSpacing={2}>
            <Grid item xs={12} display="flex" alignItems="center">
              <Box sx={{ background: blackHex, borderRadius: "24px", padding: "50px 30px", width: "100%" }}>
                <Box>
                  <Button sx={{ ...btnStyleText, color: lightergrayHex }} onClick={() => navigate("/")}>
                    <ArrowBackIcon /> Kembali ke Home
                  </Button>
                </Box>
                <Box marginTop={1}>
                  <img src={logoToko} alt="logo lique" style={{ width: "150px" }} />
                </Box>
                <Box marginBottom={3}>
                  <Typography sx={{ ...subHeaderStyle, fontWeight: "bold" }}>Daftar Akun</Typography>
                </Box>
                <Box>
                  <Typography sx={{ ...textStyle }}>Nama (Username)</Typography>
                  <TextField
                    sx={textfieldStyle}
                    placeholder="Nama"
                    value={username}
                    helperText={errors.username}
                    FormHelperTextProps={{ sx: { color: "red" } }}
                    autoComplete="off"
                    fullWidth
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </Box>
                <Box marginTop={1}>
                  <Typography sx={{ ...textStyle }}>Email</Typography>
                  <TextField
                    sx={textfieldStyle}
                    type="email"
                    placeholder="Email"
                    value={email}
                    helperText={errors.email}
                    FormHelperTextProps={{ sx: { color: "red" } }}
                    autoComplete="off"
                    fullWidth
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Box>
                <Box marginTop={1}>
                  <Typography sx={{ ...textStyle }}>No Telepon</Typography>
                  <TextField
                    sx={textfieldStyle}
                    type="text"
                    placeholder="No Telepon"
                    value={phonenumber}
                    helperText={errors.phonenumber}
                    FormHelperTextProps={{ sx: { color: "red" } }}
                    autoComplete="off"
                    fullWidth
                    onChange={(e) => setPhonenumber(e.target.value)}
                  />
                </Box>
                <Box marginTop={1}>
                  <Typography sx={{ ...textStyle }}>Password</Typography>
                  <TextField
                    sx={passwordTextfieldStyle}
                    placeholder="Password"
                    type={showPassword ? "text" : "password"}
                    // type="password"
                    value={password}
                    helperText={errors.password}
                    FormHelperTextProps={{ sx: { color: "red" } }}
                    fullWidth
                    onChange={(e) => setPassword(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" sx={passwordToggleStyle}>
                          <div
                            onClick={handleClickShowPassword}
                            style={{ cursor: "pointer", marginTop: "3px"  }}
                          >
                            { showPassword ? <VisibilityOffIcon sx={{ color: grayHex }} /> : <VisibilityIcon sx={{ color: grayHex }} />}
                          </div>
                        </InputAdornment>
                      ),
                    }}
                  ></TextField>
                </Box>
                <Box marginTop={1}>
                  <Typography sx={{ ...textStyle }}>Konfirmasi Password</Typography>
                  <TextField
                    sx={passwordTextfieldStyle}
                    placeholder="Konfirmasi Password"
                    type={showKonfirmasiPassword ? "text" : "password"}
                    // type="password"
                    value={konfirmasiPassword}
                    helperText={errors.konfirmasiPassword}
                    FormHelperTextProps={{ sx: { color: "red" } }}
                    fullWidth
                    onChange={(e) => setKonfirmasiPassword(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" sx={passwordToggleStyle}>
                          <div
                            onClick={handleClickShowKonfirmasiPassword}
                            style={{ cursor: "pointer", marginTop: "3px"  }}
                          >
                            { showKonfirmasiPassword ? <VisibilityOffIcon sx={{ color: grayHex }} /> : <VisibilityIcon sx={{ color: grayHex }} />}
                          </div>
                        </InputAdornment>
                      ),
                    }}
                  ></TextField>
                </Box>
                <Box textAlign="center">
                  <Button style={{...btnRegister}} onKeyDown={handleSubmit} onClick={handleSubmit}>
                    Register
                  </Button>
                </Box>
                <Box marginTop={2}>
                  <Typography sx={{ ...textStyle }}>
                    Sudah punya akun? <span style={{ fontWeight: "bold", cursor: "pointer" }} onClick={handleLogin}>Masuk</span>
                  </Typography>
                </Box>
                <Box marginTop={3}>
                  <Typography sx={{ ...textSmallStyle, color: lightergrayHex }}>
                    © LIQUE STORE 2025. All RIght Reserved.
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
}

export default RegisterPage;
