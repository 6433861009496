export const fontSizeHeaderBig = {
  fontSize: { xs: "2em", md: "3em" }
};

export const fontSizeHeader = {
  fontSize: { xs: "1.5em", md: "2m" }
};

export const fontSizeSubHeader = {
  fontSize: { xs: "1.2em", md: "1.8em" }
}

export const fontSizeText = {
  fontSize: { xs: ".9em", md: "1em" }
};

export const fontSizeTextSmall = {
  fontSize: { xs: ".8em", md: ".9em" }
};

export const colorBlack = {
  color: "black"
};

export const colorWhite = {
  color: "white"
}

export const colorPrimary = {
  color: "#FE8A01"
}

export const bgBlack = {
  background: "black"
}

export const bgWhite = {
  background: "white"
}

export const bgGray = {
  background: "#1D1D24"
}

export const bgPrimary = {
  background: "#FE8A01"
}

export const headerStyle = {
  ...fontSizeHeader,
  ...colorWhite,
  fontWeight: "bold"
}

export const subHeaderStyle = {
  ...fontSizeSubHeader,
  ...colorWhite
}

export const textStyle = {
  ...fontSizeText,
  ...colorWhite
}

export const textSmallStyle = {
  ...fontSizeTextSmall,
  ...colorWhite
}

export const headerStyleContrast = {
  ...fontSizeHeader,
  ...colorBlack
}

export const subHeaderStyleContrast = {
  ...fontSizeSubHeader,
  ...colorBlack
}

export const textStyleContrast = {
  ...fontSizeText,
  ...colorBlack
}

export const textSmallStyleContrast = {
  ...fontSizeTextSmall,
  ...colorBlack
}

export const orangeHex = "#FE8A01";
export const grayHex = "#1D1D24";
export const lightgrayHex = "#505862";
export const lightergrayHex = "#828B99";
export const blackHex = "#0A0A0B";

export const checkboxStyle = {
  color: "white",
  "&.MuiChecked": {
    color: "white",
  },
};

export const checkboxStyleContrast = {
  color: "black",
  "&.MuiChecked": {
    color: "black",
  },
};

export const checkboxStylePrimary = {
  color: "#FE8A01",
  "&.MuiChecked": {
    color: "#FE8A01",
  },
};

export const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  maxWidth: "80%",
  maxHeight: "80vh",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: "12px",
  overflowY: "auto",
  overflowX: "hidden"
};

export const styleModalDark = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  maxWidth: "80%",
  maxHeight: "80vh",
  bgcolor: grayHex,
  boxShadow: 24,
  p: 4,
  borderRadius: "12px",
  overflowY: "auto",
  overflowX: "hidden"
};

export const styleModalFull = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  maxWidth: "100%",
  maxHeight: "100vh",
  bgcolor: "black",
  padding: "10px",
  borderRadius: "12px",
  overflowY: "auto",
  overflowX: "hidden"
};

export const textfieldStyle = {
  input: {
    border: `2px solid ${grayHex}`,
    background: "transparent",
    borderRadius: "4px",
    color: "white",
    '&:disabled': {
      background: grayHex,
      color: lightgrayHex,
      "-webkit-text-fill-color": "unset"
    }
  },
  placeholder: {
    color: "lightgray",
  },
};

export const passwordTextfieldStyle = {
  '& .MuiInputBase-root' : {
    paddingRight: "unset",
  },
  input: {
    borderTop: `2px solid #1D1D24`,
    borderBottom: `2px solid #1D1D24`,
    borderLeft: `2px solid #1D1D24`,
    background: "transparent",
    borderTopLeftRadius: "4px",
    borderBottomLeftRadius: "4px",
    color: "white",
  },
  placeholder: {
    color: "lightgray",
  }
};

export const passwordToggleStyle = {
  borderTop: `2px solid ${grayHex}`,
  borderBottom: `2px solid ${grayHex}`,
  borderRight: `2px solid ${grayHex}`,
  borderTopRightRadius: "4px",
  borderBottomRightRadius: "4px",
  background: "transparent",
  height: "56px",
  maxHeight: "unset",
  marginLeft: "unset",
  paddingLeft: "8px",
  paddingRight: "15px",
}

export const btnStyle = {
  borderRadius: "12px",
  padding: "10px",
  color: "black",
  fontWeight: "bold",
  backgroundColor: "white",
  textTransform: "none",
  '&:hover': {
    backgroundColor: "lightgray"
  }
};

export const btnStyleContrast = {
  borderRadius: "12px",
  padding: "10px",
  color: "white",
  fontWeight: "bold",
  backgroundColor: "black",
  textTransform: "none",
  '&:hover': {
    backgroundColor: lightgrayHex
  }
};

export const btnStyleOutlined = {
  borderRadius: "12px",
  backgroundColor: "transparent",
  border: "1px solid #505862",
  padding: "10px",
  textTransform: "unset",
  color: "white"
};

export const btnStyleText = {
  backgroundColor: "transparent",
  border: "none",
  padding: "10px",
  textTransform: "unset",
  color: "white"
};

export const roudedIconButtonStyle = {
  borderRadius: "100px",
  width: { xs: "30px" , md: "40px"},
  height: { xs: "30px" , md: "40px"},
  minWidth: "unset",
  backgroundColor: lightgrayHex,
  color: "white",
  padding: "0px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  border: "none",
  '&:hover': {
    backgroundColor: grayHex
  }
};
