import axios from "axios"

const backendUrl = process.env.REACT_APP_BACKEND_URL;
const cleanedBackendUrl = backendUrl.replace("/backend", "");

export const AuthService = {
    updatePassword(customerId, oldPassword, newPassword, confirmPassword) {
        return axios.put(
            `${cleanedBackendUrl}/customer/${customerId}/password`,
            {
                oldPassword: oldPassword,
                newPassword: newPassword,
                confirmationPassword: confirmPassword
            }
        );
    },
};