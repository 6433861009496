import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import liqueLogo from "../assets/liqueLogo.png";
import { colorPrimary, colorWhite, fontSizeHeaderBig, fontSizeText } from "../helper/style";

const MaintenancePage = () => {
  return (
		<>
			<Container component="main" maxWidth="xl" sx={{ marginBottom: "50px" }}>
				<Box sx={{ height: '90vh' }} display="flex" alignItems="center">
					<Grid container justifyContent="center" alignItems="center">
						<Grid item xs={12} md={6}>
							<img src={liqueLogo} alt="Logo Liquestore" style={{ width: "100%" }}></img>
						</Grid>
						<Grid item xs={12} md={6} textAlign="center">
							<Typography sx={{ ...fontSizeHeaderBig, ...colorPrimary, fontWeight: "bold" }}>We're under Maintenance</Typography>
							<Typography sx={{ ...fontSizeText, ...colorWhite }}>Our website is down for maintenance. We will be back shortly</Typography>
						</Grid>
					</Grid>
				</Box>
			</Container>
		</>
	);
};

export default MaintenancePage;
