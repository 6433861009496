import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Navigation from "./navigation";
import { Accordion, AccordionDetails, AccordionSummary, Alert, Box, Button, Checkbox, Container, FormControlLabel, Grid, IconButton, LinearProgress, linearProgressClasses, MenuItem, Select, Snackbar, SnackbarContent, styled, TextField, Typography } from "@mui/material";
import CustomerService from "../services/customer/customerService";
import ItemCard from "./components/item/itemCard";
import TagService from "../services/manager/tagService";
import Footer from "./components/footer/footer";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import TuneIcon from '@mui/icons-material/Tune';
import CloseIcon from '@mui/icons-material/Close';
import { btnStyle, btnStyleOutlined, checkboxStyle, checkboxStyleContrast, colorWhite, fontSizeHeader, fontSizeTextSmall, grayHex, headerStyle, roudedIconButtonStyle, subHeaderStyle, textSmallStyle, textStyle, textStyleContrast } from "../helper/style";
import LiqueAccordion from "./components/liqueAccordion";
import LiqueModal from "./components/liqueModal";

const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 10,
  borderRadius: 12,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: 'white',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#6A6A6A",
  },
}));

const btnDisabledStyle = {
  borderRadius: "12px",
  width: "100%",
  backgroundColor: "white",
  color: "black",
  padding: 5,
  fontSize: "1em",
  opacity: 0.5
};

const MoreItemsPage = () => {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const navigate = useNavigate();
  const location = useLocation();
  const [queryParams, setQueryParams] = useState(new URLSearchParams(location.search));
  const [submittedItemName, setSubmittedItemName] = useState(queryParams.get("search"));
  const [tag, setTag] = useState(queryParams.get("tag")); 
  const [variant, setVariant] = useState(queryParams.get("variant")); 
  const cleanedBackendUrl = backendUrl.replace("/backend", "");
  const [ShowSuccess, setShowSuccess] = useState(false);
  const [MsgSuccess, setMsgSuccess] = useState("");
  const [showError, setShowError] = useState(false);
  const [msgError, setMsgError] = useState();
  const [itemTypeList, setItemTypeList] = useState([]);
  const [tagSecondaryList, setTagSecondaryList] = useState([]);
  const [tagMainList, setTagMainList ] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [itemList, setItemList] = useState([]);
  const [tempItemList, setTempItemList] = useState([]);
  const [title, setTitle] = useState([]);
  const [selectedMainTag, setSelectedMainTag] = useState([]);
  const [selectedSecondaryTag, setSelectedSecondaryTag] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState([]);
  const [selectedType, setSelectedType] = useState(variant ? [parseInt(variant)] : []);
  const [openFilter, setOpenFilter] = useState(false);
  const [chosenTag, setChosenTag] = useState(null);
  const [sortBy, setSortBy] = useState("1");
  const [page, setPage] = useState(1);
  const [totalItem, setTotalItem] = useState(0);
  const [totalPage, setTotalPage] = useState(1);
  const [firstLoad, setFirstLoad] = useState(true);
  const perPage = 10;
  

  const activateSuccess = () => {
    setShowSuccess(true);
    setTimeout(() => {
      setShowSuccess(false);
    }, 5000);
  };

  const activateError = () => {
    setShowError(true);
    setTimeout(() => {
      setShowError(false);
    }, 5000);
  };

  const onTypeSelected = (id) => {
    setPage(1);
    setTempItemList([]);
    const find = selectedType.find(tag => tag === id);
    if (find) {
      setSelectedType(selectedType.filter(tag => tag !== id))
    } else {
      setSelectedType([...selectedType, id])
    }
  }

  const onMainTagSelected = (id) => {
    setPage(1);
    setTempItemList([]);
    const find = selectedMainTag.find(tag => tag === id);
    if (find) {
      setSelectedMainTag(selectedMainTag.filter(tag => tag !== id))
    } else {
      setSelectedMainTag([...selectedMainTag, id])
    }
  }
  
  const onSecondaryTagSelected = (id) => {
    setPage(1);
    setTempItemList([]);
    const find = selectedSecondaryTag.find(tag => tag === id);
    if (find) {
      setSelectedSecondaryTag(selectedSecondaryTag.filter(tag => tag !== id))
    } else {
      setSelectedSecondaryTag([...selectedSecondaryTag, id])
    }
  }
  
  const onBrandSelected = (id) => {
    setPage(1);
    setTempItemList([]);
    const find = selectedBrand.find(tag => tag === id);
    if (find) {
      setSelectedBrand(selectedBrand.filter(tag => tag !== id))
    } else {
      setSelectedBrand([...selectedBrand, id])
    }
  }

  const onChangeSortBy = (data) => {
    setPage(1);
    setTempItemList([]);
    setSortBy(data);
  }

  const getItemTypeList = async () => {
    try {
      const response = await CustomerService.getItemTypeList();
      if (response) {
        const data = response.data;
        setItemTypeList(data.itemTypeList);
      }
    } catch (error) {
      console.error("There was an error fetching the data!", error);
    }
  };

  const getAllTag = async () => {
    try {
      const response = await TagService.getTagList("");
      if (response) {
        const data = response.data.tagModelList;
        setTagSecondaryList(data.filter(d => d.flag === 'secondary'));
        setTagMainList(data.filter(d => d.flag === 'main'));
        setBrandList(data.filter(d => d.flag === 'brand'));

        let title = ""
        if (tag) {
          const tagFound = data.find(t => t.id === parseInt(tag));
          setChosenTag(tagFound);
          title = tagFound?.title;

          switch (tagFound.flag) {
            case "main":
              setSelectedMainTag([tagFound.id]);
              break;
            case "secondary":
              setSelectedSecondaryTag([tagFound.id]);
              break;
            case "brand":
              setSelectedBrand([tagFound.id]);
              break;
            default:
              break;
          }
        }
        setTitle(title)
      }
    } catch (error) {
      console.error(error);
      setMsgError("Error while fetching list tags");
      activateError();
    }
  };

  async function fetchItems() {
    try {
      let by = null;
      let direction = "asc";

      if (sortBy === "1") {
        by = "date";
        direction = "desc";
      } else if (sortBy === "2") {
        by = "price";
        direction = "asc";
      } else if (sortBy === "3") {
        by = "price";
        direction = "desc";
      }

      const responseItemList = await CustomerService.getListItemPageable(
        page,
        perPage,
        submittedItemName,
        selectedMainTag.join(","),
        selectedSecondaryTag.join(","),
        selectedBrand.join(","),
        selectedType.join(","),
        by,
        direction
      );
      
      if (responseItemList) {
        const data = responseItemList.data;
        setItemList([...tempItemList, ...data.itemList]);
        setTempItemList([...tempItemList, ...data.itemList]);
        setTotalItem(data.totalData);
        setTotalPage(data.totalPage);

        if (data.itemList.length > 0) {
          setPage(page + 1);
        }
      }

    } catch (error) {
      console.error(error);
    }
  }

  const resetFilter = () => {
    setSelectedMainTag([]);
    setSelectedSecondaryTag([]);
    setSelectedBrand([]);
    setSelectedType([]);
  }

  const loadNecessaryData = async () => {
    await getItemTypeList();
    await getAllTag();
  
    fetchItems();
  }

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setQueryParams(params);
  
    const search = params.get("search");
    const tag = params.get("tag");
    const variant = params.get("variant");
  
    setSubmittedItemName(search);
    setTag(tag);
    setVariant(variant);
  
    window.scrollTo(0, 0);
    
    if (search || tag || variant) {
      setPage(1);
      setTempItemList([]);
  
      loadNecessaryData();
    }
  }, [location.search]);
  
  useEffect(() => {
    fetchItems();
  }, [chosenTag, selectedMainTag, selectedSecondaryTag, selectedBrand, selectedType, sortBy]);

  return (
    <>
      <Navigation />

      <Container component="main" maxWidth="xl" sx={{ minHeight: "100vh" }}>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={ShowSuccess}
          autoHideDuration={3000}
          onClose={() => setShowSuccess(false)}
        >
          <SnackbarContent
            sx={{ backgroundColor: "#19B600" }} 
            message={MsgSuccess}/>
        </Snackbar>
  
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={showError}
          autoHideDuration={3000}
          onClose={() => setShowError(false)}
        >
          <SnackbarContent 
            sx={{ backgroundColor: "#E50B0B" }} 
            message={msgError}/>
        </Snackbar>

        <Grid container marginTop={5} columnSpacing={5}>
          <Grid item xs={8} md={12} display="flex" alignItems="center">
            {
              submittedItemName ? (
                <>
                  <Typography sx={{ ...textSmallStyle }}>
                    Hasil pencarian untuk
                  </Typography>
                  <Typography sx={{ ...headerStyle }}>
                    { submittedItemName }
                  </Typography>
                </>
              ) : (
                <Typography sx={{ ...headerStyle }} fontWeight="bold" textTransform="uppercase">
                  Semua Produk
                </Typography>
              )
            }
          </Grid>
          <Grid item xs={4} md={12} sx={{ display: {xs: "block", md: "none"}, textAlign: "end" }}>
            <Button sx={{ ...btnStyleOutlined, borderRadius: "50px" }} onClick={() => setOpenFilter(true)}>
              <span style={{ display: "inline-block", marginRight: "10px" }}>Filter</span> <TuneIcon />
            </Button>
          </Grid>
        </Grid>

        <Grid container marginBottom={5} columnSpacing={5} sx={{ position: "relative" }}>
          <Grid item xs={12} md={4} lg={3} marginBottom={2} 
            sx={{ 
              order: {xs: 3, md: "unset"}, 
              position: "sticky", 
              top: "60px", 
              alignSelf: "flex-start",
              display: {xs: "none", md: "block"} 
            }}>
            <LiqueAccordion defaultExpanded title="Gender">
              { 
                tagMainList.map(tag =>
                  <FormControlLabel 
                    key={tag.id}
                    label={tag.title}
                    control={
                      <Checkbox
                        value={tag.id}
                        checked={selectedMainTag.includes(tag.id)}
                        onChange={() => onMainTagSelected(tag.id)}
                        sx={{ ...checkboxStyle }}
                      />
                    }
                    sx={{ ...textStyle, display: "flex", flexDirection: "row" }}
                  />
                )
              }
            </LiqueAccordion>

            <LiqueAccordion defaultExpanded title="Product Type">
              {
                itemTypeList.map(itemType =>
                  <FormControlLabel 
                    key={itemType.id}
                    label={`${itemType.name}-${itemType.variant}`}
                    control={
                      <Checkbox
                        value={itemType.id}
                        checked={selectedType.includes(itemType.id)}
                        onChange={() => onTypeSelected(itemType.id)}
                        sx={{ ...checkboxStyle }}
                      />
                    }
                    sx={{ ...textStyle, display: "flex", flexDirection: "row" }}
                  />
                )
              }
            </LiqueAccordion>

            <LiqueAccordion defaultExpanded title="Tag">
              {
                tagSecondaryList.map(tag =>
                  <FormControlLabel 
                    key={tag.id}
                    label={tag.title}
                    control={
                      <Checkbox
                        value={tag.id}
                        checked={selectedSecondaryTag.includes(tag.id)}
                        onChange={() => onSecondaryTagSelected(tag.id)}
                        sx={{ ...checkboxStyle }}
                      />
                    }
                    sx={{ ...textStyle, display: "flex", flexDirection: "row" }}
                  />
                )
              }
            </LiqueAccordion>
            
            <LiqueAccordion defaultExpanded title="Brand">
              {
                brandList.map(brand =>
                  <FormControlLabel 
                    key={brand.id}
                    label={brand.title}
                    control={
                      <Checkbox
                        value={brand.id}
                        checked={selectedBrand.includes(brand.id)}
                        onChange={() => onBrandSelected(brand.id)}
                        sx={{ ...checkboxStyle }}
                      />
                    }
                    sx={{ ...textStyle, display: "flex", flexDirection: "row" }}
                  />
                )
              }
            </LiqueAccordion>
          </Grid>

          {
            itemList.length > 0 ? (
              <Grid item xs={12} md={8} lg={9} marginBottom={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid container columnSpacing={3}>
                      {/* <Grid item xs={12} md={4} display="flex" alignItems="center" marginBottom={1}>
                        <Typography variant="h5" sx={{ ...textStyle }} ><b>{totalItem}</b> items found</Typography>
                      </Grid> */}
                      <Grid item xs={12} md={12} fontSize="1.2em" textAlign="end" sx={{ display: {xs: "none", md: "block"}  }}>
                        <Select
                          labelId="label-size"
                          id="select-size"
                          value={sortBy}
                          label="Age"
                          onChange={(event) => onChangeSortBy(event.target.value)}
                          sx={{  
                            ...textSmallStyle,
                            '& .MuiSelect-icon': {
                              ...colorWhite
                            },
                          }}
                        >
                          <MenuItem value="1">Terbaru</MenuItem>
                          <MenuItem value="2">Harga Termurah</MenuItem>
                          <MenuItem value="3">Harga Termahal</MenuItem>
                        </Select>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={0}>
                  {itemList.map((item) => (
                    <Grid item xs={6} md={4} key={item.id}>
                      <ItemCard key={item.id} item={item} />
                    </Grid>
                  ))}
                  {itemList.length <= 0 && (
                    <Grid container >
                      <Grid item xs={12} textAlign="center" marginTop={3}>
                        <Typography variant="h6" textAlign="center" color={"black"}>
                          No item found
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </Grid>

                <Grid container justifyContent="center" marginTop={3}>
                  <Grid item lg={4} md={6} sm={8} xs={12}>
                    <Typography variant="h6" textAlign="center" sx={{ fontSize: { xs: ".8em", md: "1.2em" } }} >
                      Showing {itemList.length} of {totalItem}
                    </Typography>
                    {/* <Box marginTop={2}>
                      <BorderLinearProgress variant="determinate" value={itemList.length / (totalItem || 1) * 100} />
                    </Box> */}
                    <Box textAlign="center" marginTop={2}>
                      {
                        itemList.length < parseInt(totalItem) && (
                          <Button style={itemList.length === parseInt(totalItem) ? btnDisabledStyle : btnStyle} 
                            onClick={fetchItems} disabled={itemList.length === parseInt(totalItem)}>Show More</Button>
                        )
                      }
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Grid item xs={12} md={8} lg={9} marginBottom={2} height="50vh" display="flex" justifyContent="center" alignItems="center">
                <Box textAlign="center">
                  <Box>
                    <SearchIcon sx={{ ...colorWhite, fontSize: 100 }} />
                  </Box>
                  <Typography sx={{ ...headerStyle }}>Hasil pencarian tidak ditemukan</Typography>
                  <Typography sx={{ ...textStyle }}>Silahkan gunakan kata kunci lainnya</Typography>
                </Box>
              </Grid>
            )
          }


        </Grid>
      </Container>

      <Footer />

      <LiqueModal open={openFilter} onClose={() => setOpenFilter(false)} theme="full">
        <Box>
          <Grid container position="sticky" top="0" zIndex={5} sx={{ background: "black", padding: "20px" }}>
            <Grid item xs={8} display="flex" alignItems="center">
              <Typography sx={{ ...subHeaderStyle }}>Filter</Typography>
            </Grid>
            <Grid item xs={4} display="flex" justifyContent="end">
              <Button sx={roudedIconButtonStyle} onClick={() => setOpenFilter(false)}>
                <CloseIcon />
              </Button>
            </Grid>
          </Grid>
          <Box paddingX="20px">
            <Box sx={{ paddingY: "10px", borderBottom: `1px solid ${grayHex}` }}>
              <Typography sx={{ ...textStyle, fontWeight: "bold" }}>Gender</Typography>
              { 
                tagMainList.map(tag =>
                  <FormControlLabel 
                    key={`mobile-${tag.id}`}
                    label={tag.title}
                    control={
                      <Checkbox
                        value={tag.id}
                        checked={selectedMainTag.includes(tag.id)}
                        onChange={() => onMainTagSelected(tag.id)}
                        sx={{ ...checkboxStyle }}
                      />
                    }
                    sx={{ ...textStyle, display: "flex", flexDirection: "row" }}
                  />
                )
              }
            </Box>
            <Box sx={{ paddingY: "10px", borderBottom: `1px solid ${grayHex}` }}>
              <Typography sx={{ ...textStyle, fontWeight: "bold" }}>Product Type</Typography>
              {
                itemTypeList.map(itemType =>
                  <FormControlLabel 
                    key={`mobile-${itemType.id}`}
                    label={`${itemType.name}-${itemType.variant}`}
                    control={
                      <Checkbox
                        value={itemType.id}
                        checked={selectedType.includes(itemType.id)}
                        onChange={() => onTypeSelected(itemType.id)}
                        sx={{ ...checkboxStyle }}
                      />
                    }
                    sx={{ ...textStyle, display: "flex", flexDirection: "row" }}
                  />
                )
              }
            </Box>
            <Box sx={{ paddingY: "10px", borderBottom: `1px solid ${grayHex}` }}>
              <Typography sx={{ ...textStyle, fontWeight: "bold" }}>Tag</Typography>
              {
                tagSecondaryList.map(tag =>
                  <FormControlLabel 
                    key={`mobile-${tag.id}`}
                    label={tag.title}
                    control={
                      <Checkbox
                        value={tag.id}
                        checked={selectedSecondaryTag.includes(tag.id)}
                        onChange={() => onSecondaryTagSelected(tag.id)}
                        sx={{ ...checkboxStyle }}
                      />
                    }
                    sx={{ ...textStyle, display: "flex", flexDirection: "row" }}
                  />
                )
              }
            </Box>
            <Box sx={{ paddingY: "10px", borderBottom: `1px solid ${grayHex}` }}>
              <Typography sx={{ ...textStyle, fontWeight: "bold" }}>Brand</Typography>
              {
                brandList.map(brand =>
                  <FormControlLabel 
                    key={`mobile-${brand.id}`}
                    label={brand.title}
                    control={
                      <Checkbox
                        value={brand.id}
                        checked={selectedBrand.includes(brand.id)}
                        onChange={() => onBrandSelected(brand.id)}
                        sx={{ ...checkboxStyle }}
                      />
                    }
                    sx={{ ...textStyle, display: "flex", flexDirection: "row" }}
                  />
                )
              }
            </Box>
          </Box>
          <Grid container position="sticky" bottom="0" columnSpacing={1} zIndex={5} sx={{ background: "black", padding: "20px" }}>
            <Grid item xs={6}>
              <Button sx={{ ...btnStyleOutlined, width: "100%", borderRadius: "50px" }} onClick={resetFilter}>
                Reset
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button sx={{ ...btnStyle, width: "100%", borderRadius: "50px" }} onClick={() => setOpenFilter(false)}>
                Terapkan
              </Button>
            </Grid>
          </Grid>
        </Box>
      </LiqueModal>
    </>
  );
};

export default MoreItemsPage;
