import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import axios from "axios";
import { Alert, Box, Grid, InputAdornment } from "@mui/material";
import { useAuth } from "./authContext";
import { Link, useLocation, useNavigate } from "react-router-dom";
import backgroundImage from "./assets/liqueLoginBg.png";
import logoToko from "./assets/liqueLogo.png";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Navigation from "./customer/navigation";
import { blackHex, btnStyleText, colorBlack, colorWhite, fontSizeSubHeader, fontSizeText, grayHex, headerStyle, lightergrayHex, lightgrayHex, passwordTextfieldStyle, passwordToggleStyle, subHeaderStyle, textfieldStyle, textSmallStyle, textStyle } from "./helper/style";

const containerStyle = {
  backgroundColor: "black",
  color: "white",
  borderRadius: 25,
  boxShadow: "10px 10px 5px grey",
};

const triangleDown = {
  width: 0,
  height: 0,
  borderLeft: "40px solid transparent",
  borderRight: "40px solid transparent",
  borderTop: "40px solid black",
  position: "absolute",
  left: "40%"
}

const btnLogin = {
  marginTop: "20px",
  justifyContent: "center",
  borderRadius: "50px",
  backgroundColor: "white",
  color: "black",
  width: "100%",
  maxWidth: "100%"
};

function LoginPage() {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const [showSuccess, setShowSuccess] = useState(false);
  const [msgSuccess, setmsgSuccess] = useState("");
  const [showError, setShowError] = useState(false);
  const [msgError, setMsgError] = useState();
  const [param, setParam] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const { login } = useAuth();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const orderidFromQuery = params.get("orderid");
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleRegister = () => {
    // if (param) {
    //   navigate(`/register?orderid=${orderidFromQuery}`);
    // } else {
    //   navigate(`/register`)
    // }
    navigate('/maintenance');
  }

  useEffect(() => {
    if (orderidFromQuery != null) {
      setParam(orderidFromQuery);
    } else {
      setParam("");
    }
  }, [orderidFromQuery]);

  useEffect(() => {
    const storedMessage = localStorage.getItem("berhasilRegister");
    if (storedMessage) {
      setShowSuccess(true);
      setmsgSuccess(storedMessage);
      setTimeout(() => {
        setShowSuccess(false);
      }, 5000);
      localStorage.removeItem("berhasilRegister");
    }
  }, []);

  const validate = () => {
    let tempErrors = {};
    if (!username) {
      tempErrors.username = "Username harus diisi";
    }
    if (!password) {
      tempErrors.password = "Password harus diisi";
    }
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      const formData = new FormData();
      formData.append("username", username);
      formData.append("password", password);
      formData.append("orderid", orderidFromQuery);
      try {
        const response = await axios.post(`${backendUrl}/login`, formData);
        console.log(response.data);
        if (response.data.customer) {
          if (orderidFromQuery != null && response.data.cekPhoneOrder) {
            localStorage.setItem("orderid", orderidFromQuery);
          }
          login(response.data.customer);
        } else {
          login(response.data);
        }
      } catch (error) {
        console.error("error fetching data: ", error);
        setShowError(true);
        setMsgError("Invalid Username or Password");
        setTimeout(() => {
          setShowError(false);
        }, 5000);
      }
    }
  };

  return (
    <>
      <Container component="main" maxWidth="lg" sx={{ marginY: "50px", minHeight: "80vh" }}>
        {showSuccess && (
          <Alert variant="filled" severity="success" sx={{ marginBottom: 5 }}>
            {msgSuccess}
          </Alert>
        )}
        {showError && (
          <Alert variant="filled" severity="error" sx={{ marginBottom: 5 }}>
            {msgError}
          </Alert>
        )}
        
        <Box>
          <Grid container spacing={1} marginTop={1} columnSpacing={2}>
            <Grid item xs={12} md={6} sx={{ display: {xs: "none", md: "block"} }}>
              <img src={backgroundImage} alt="background lique" style={{ width: "100%" }} />
            </Grid>
            <Grid item xs={12} md={6} display="flex" alignItems="center">
              <Box sx={{ background: blackHex, borderRadius: "24px", padding: "50px 30px", width: "100%" }}>
                <Box>
                  <Button sx={{ ...btnStyleText, color: lightergrayHex }} onClick={() => navigate("/")}>
                    <ArrowBackIcon /> Kembali ke Home
                  </Button>
                </Box>
                <Box marginTop={1}>
                  <img src={logoToko} alt="logo lique" style={{ width: "150px" }} />
                </Box>
                <Box marginBottom={3}>
                  <Typography sx={{ ...subHeaderStyle, fontWeight: "bold" }}>Masuk</Typography>
                </Box>
                <Box>
                  <Typography sx={{ ...textStyle }}>Username / No Telepon / Email</Typography>
                  <TextField
                    sx={textfieldStyle}
                    className="input"
                    placeholder="Masukkan username / no telepon / email"
                    value={username}
                    autoComplete="off"
                    fullWidth
                    helperText={errors.username}
                    FormHelperTextProps={{ sx: { color: "red" } }}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </Box>
                <Box marginTop={1}>
                  <Typography sx={{ ...textStyle }}>Password</Typography>
                  <TextField
                    sx={passwordTextfieldStyle}
                    placeholder="Masukkan password"
                    type={showPassword ? "text" : "password"}
                    value={password}
                    fullWidth
                    helperText={errors.password}
                    FormHelperTextProps={{ sx: { color: "red" } }}
                    onChange={(e) => setPassword(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" sx={passwordToggleStyle}>
                          <div
                            onClick={handleClickShowPassword}
                            style={{ cursor: "pointer", marginTop: "3px" }}
                          >
                            { showPassword ? <VisibilityOffIcon sx={{ color: lightgrayHex }} /> : <VisibilityIcon sx={{ color: lightgrayHex }} />}
                          </div>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <Box textAlign="end" marginTop={1}>
                  <Typography sx={{ ...textStyle }} component={Link} to="/maintenance">
                    Lupa Password?
                  </Typography>
                </Box>
                <Box textAlign="center">
                  <Button style={{...btnLogin}} onKeyDown={handleSubmit} onClick={handleSubmit}>
                    Login
                  </Button>
                </Box>
                <Box marginTop={2}>
                  <Typography sx={{ ...textStyle }}>
                    Belum punya akun? <span style={{ fontWeight: "bold", cursor: "pointer" }} onClick={handleRegister}>Daftar</span>
                  </Typography>
                </Box>
                <Box marginTop={3}>
                  <Typography sx={{ ...textSmallStyle, color: lightergrayHex }}>
                    © LIQUE STORE 2025. All RIght Reserved.
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
}

export default LoginPage;
