import React, { useEffect, useState } from "react";
import { Box, Grid, SvgIcon, Typography } from "@mui/material";
import "./footer.css";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from '@mui/icons-material/YouTube';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

import logoToko from "../../../assets/liqueLogo.png";
import { useNavigate } from "react-router-dom";
import { textSmallStyle, textStyle } from "../../../helper/style";
import CustomerService from "../../../services/customer/customerService";

const Footer = () => {
  const navigate = useNavigate();
  const [itemTypeList, setItemTypeList] = useState([]);

  const getItemTypeList = async () => {
    try {
      const response = await CustomerService.getItemTypeList();
      if (response) {
        const data = response.data;
        setItemTypeList(data.itemTypeList);
      }
    } catch (error) {
      console.error("There was an error fetching the data!", error);
    }
  };

  useEffect(() => {
    getItemTypeList();
  }, []);

  return (
    <>
      <Box padding={1} sx={{ background: "black", color: "white" }}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <img
              className="image-logo"
              src={logoToko}
              alt="Lique Store Logo"
              style={{ cursor: "pointer", maxWidth: "100%" }}
              onClick={() => navigate('/')}
            />
          </Grid>
          <Grid item xs={6}>
            <Grid container sx={{ height: "100%" }} spacing={3} justifyContent="end">
              <Grid item xs={12} sm={8} md={6} height="fit-content" alignSelf="flex-end">
                <Grid container justifyContent="end">
                  <Grid item xs={3} sm={2} md={3} lg={2} display="flex" justifyContent="center" alignItems="center">
                    <SvgIcon color="white" sx={{ fontSize: {xs: 21, md: 31} }}>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                        {" "}
                        <path fill="white" d="M448 209.9a210.1 210.1 0 0 1 -122.8-39.3V349.4A162.6 162.6 0 1 1 185 188.3V278.2a74.6 74.6 0 1 0 52.2 71.2V0l88 0a121.2 121.2 0 0 0 1.9 22.2h0A122.2 122.2 0 0 0 381 102.4a121.4 121.4 0 0 0 67 20.1z" />
                      </svg>
                    </SvgIcon>
                  </Grid>
                  <Grid item xs={3} sm={2} md={3} lg={2} display="flex" justifyContent="center" alignItems="center">
                    <InstagramIcon sx={{ fontSize: {xs: 24, md: 34} }} />
                  </Grid>
                  <Grid item xs={3} sm={2} md={3} lg={2} display="flex" justifyContent="center" alignItems="center">
                    <WhatsAppIcon sx={{ fontSize: {xs: 24, md: 34} }} />
                  </Grid>
                  <Grid item xs={3} sm={2} md={3} lg={2} display="flex" justifyContent="center" alignItems="center">
                    <YouTubeIcon sx={{ fontSize: {xs: 30, md: 40} }} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container padding={3}>
              <Grid item xs={12} md={3} display="flex" flexDirection="column" gap="10px" marginBottom={2}>
                <Typography fontWeight="bold" color="white" sx={{ ...textStyle }}>Kategori</Typography>
                {
                  itemTypeList.map(itemType => (
                    <Typography sx={{ cursor: "pointer", ...textSmallStyle }} key={itemType.id}>
                      <span onClick={() => navigate(`/items?variant=${itemType.id}`)}>{itemType.name}</span>
                    </Typography>
                  ))
                }
              </Grid>

              <Grid item xs={12} md={3} display="flex" flexDirection="column" gap="10px" marginBottom={2}>
                <Typography fontWeight="bold" color="white" sx={{ ...textStyle }}>Tentang Kami</Typography>
                <Typography sx={{ cursor: "pointer", ...textSmallStyle }}>
                  <span onClick={() => navigate("/about")}>Tentang Lique Store</span>
                </Typography>
                <Typography sx={{ cursor: "pointer", ...textSmallStyle }}>Ulasan Pelanggan</Typography>
                <Typography sx={{ cursor: "pointer", ...textSmallStyle }}>Temukan lokasi kami</Typography>
                <Typography sx={{ cursor: "pointer", ...textSmallStyle }}>
                  <span onClick={() => navigate("/about#karir")}>Karir</span>
                </Typography>
              </Grid>
              <Grid item xs={12} md={3} display="flex" flexDirection="column" gap="10px" marginBottom={2}>
                <Typography fontWeight="bold" color="white" sx={{ ...textStyle }}>Logistik</Typography>
                <Typography sx={{ cursor: "pointer", ...textSmallStyle }}>Status Order</Typography>
                <Typography sx={{ cursor: "pointer", ...textSmallStyle }}>Pengiriman & Pengantaran</Typography>
                <Typography sx={{ cursor: "pointer", ...textSmallStyle }}>
                  <span onClick={() => navigate("/about#kebijakan-pengembalian")}>Kebijakan Pengembalian</span>
                </Typography>
                <Typography sx={{ cursor: "pointer", ...textSmallStyle }}>Pilihan Pembayaran</Typography>
              </Grid>
              <Grid item xs={12} md={3} display="flex" flexDirection="column" gap="10px" marginBottom={2}>
                <Typography fontWeight="bold" color="white" sx={{ ...textStyle }}>Lainnya</Typography>
                <Typography sx={{ cursor: "pointer", ...textSmallStyle }}>Bantuan</Typography>
                <Typography sx={{ cursor: "pointer", ...textSmallStyle }}>
                  <span onClick={() => navigate("/about#contact-us")}>Kontak Kami</span>
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} marginY={1}>
            <hr style={{ height: '1px', width: "97%", margin: "auto", background: '#505862', border: "none" }}></hr>
          </Grid>

          <Grid item xs={12}>
            <Box display="flex" sx={{ flexDirection: {xs: "column", md: "row"} }} justifyContent="space-between" padding={3}>
              <Box sx={{ order: {xs: "2", md: "1"} }}>
                <Typography sx={{...textSmallStyle}}>© LIQUE STORE 2025 | All RIght Reserved</Typography>
              </Box>
              <Box display="flex" sx={{ gap: "10px", order: {xs: "1", md: "2"} }}>
                <Typography sx={{cursor: "pointer", ...textSmallStyle}}>Kebijakan Privasi</Typography>
                <Typography sx={{cursor: "pointer", ...textSmallStyle}}>Syarat & Ketentuan</Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Footer;
